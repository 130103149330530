import { Box } from '@mui/material';
import { Column } from 'components/Column';
import BasicPagination from 'components/Pagination';
import ListItem from 'components/ListItem';
import { Text } from 'components/Text';
import React from 'react'

function ProductList({wid}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {wid},
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        borderRadius:5,
        boxShadow: 24,
      
        py:5,
        px:8
      };
  return (
    <Box className='w-[60%] md:w-[600px]' sx={style}>
        <Column>
        <Text
          className="not-italic text-gray_900 w-[auto]"
          variant="h3"
        >
        Products
        </Text>
        <Text
          className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
          944 Total Products
        </Text>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-2 my-[15px]'>
        <ListItem desc='Ibuprofen' name='' icon='producthunt' />
        <ListItem desc='Ibuprofen' name='' icon='producthunt' />
        <ListItem desc='Ibuprofen' name='' icon='producthunt' />
        <ListItem desc='Ibuprofen' name='' icon='producthunt' />
        <ListItem desc='Ibuprofen' name='' icon='producthunt' />
        <ListItem desc='Ibuprofen' name='' icon='producthunt' />
       
        </div>
      <BasicPagination />
        </Column>
    
  </Box>  )
}



export default ProductList