import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Select } from "@mui/material";
import InstitutionLookUp from "components/LookUpElements/institution";
import { Row } from "components/Row";
import { useGetInstitutionsQuery } from "features/institution/institutionApiSlice";
import React, { useEffect } from "react";
import "../../styles/general.css";
import { FilterList } from "@material-ui/icons";
import { Box } from "@mui/system";

const Filter = ({ setActive, all, active, setFiltered, suppliers }) => {
  const { data } = useGetInstitutionsQuery();
  const lookup = new Map();
  data?.forEach((element) => {
    lookup[element.id] = element.name;
  });
  const list = suppliers?.map((element) => {
    return (
      <option value={element}>{lookup[element]}</option>
      // <MenuItem value={element}>
      //   {" "}
      //   <InstitutionLookUp id={element}></InstitutionLookUp>
      // </MenuItem>
      // <button
      //   onClick={() => {
      //     setActive(element);
      //   }}
      //   className={active == element ? "activee" : ""}
      // >

      // </button>
    );
  });
  // const list = suppliers?.map((element) => {
  //   return (
  //     <button
  //       onClick={() => {
  //         setActive(element);
  //       }}
  //       className={active == element ? "activee" : ""}
  //     >
  //       <InstitutionLookUp id={element}></InstitutionLookUp>
  //     </button>
  //   );
  // });
  useEffect(() => {
    if (active == 0) {
      setFiltered(all);
      return;
    }
    const filtered = all.filter((element) => {
      return element.owner_institution == active;
    });

    setFiltered(filtered);
  }, [active]);

  return (
    <Row>
      {/* <button   className={active == 0 ? "activee" : ""} onClick={() => setActive(0)}>All</button>
      {list} */}

      <Box
        className=" bg-gray_50 border border-gray_50 border-solid rounded-radius845"
        sx={{
          display: "flex",
          alignItems: "flex-end",
          height: "42px",
          backgroundColor: "rgba(40, 54, 96, 0.2)",
        }}
      >
        <FilterList className="my-auto mx-2" sx={{ color: "#ffffff" }} />{" "}
        <select
          // color="secondary"
          className="px-3 bg-gray_50 2xl:mt-[9px]  3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[40px] sm:h-[40px] h-[40px] lg:mt-[6px] w-[160px]  sm:w-[300px]  xl:mt-[8px] border border-gray_50 border-solid rounded-radius845"
          value={active}
          prefix="vh"
          onChange={(element) => {
            setActive(element.target.value);
          }}
          autoWidth
          label="Suppliers"
        >
          <option value={0}>All Suppliers</option>
          {list}
        </select>
        {/* 
      <TextField size='medium' sx={{borderRadius: 30, padding:5, margin:5 }} color='secondary' value={value} onChange={onChange} name="search-form"  id="search-form" label={placeholder} variant="outlined" /> */}
      </Box>
      {/* <select
        // color="secondary"
        className="px-3 bg-gray_50 2xl:mt-[9px]  3xl:mt-[10px] flex 3xl:h-[56px] 2xl:h-[47px] xl:h-[41px] lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[160px]  sm:w-[300px]  xl:mt-[8px] border border-gray_50 border-solid rounded-radius845"
        value={active}
        prefix="vh"
        onChange={(element) => {
          console.log(element);
          setActive(element.target.value);
        }}
        autoWidth
        label="Suppliers"
      >
        <option>All Suppliers</option>
        {list}
      </select> */}
      {/* <Select
        className="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] xl:h-[41px] xl:mt-[8px]"
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={active}
        onChange={(element) => {
          console.log(element);
          setActive(element.target.value);
        }}
        autoWidth
        label="Suppliers"
      >
        <MenuItem value={0}>
          <em>All Suppliers</em>
        </MenuItem>
        {list}
      </Select> */}
    </Row>
  );
};

export default Filter;
// import InstitutionLookUp from "components/LookUpElements/institution";
// import React, { useEffect } from "react";
// import "../../styles/general.css";
// const Filter = ({ setActive, all, active, setFiltered, suppliers }) => {
//   console.log("all", suppliers);
//   const list = suppliers?.map((element) => {
//     return (
//       <button
//         onClick={() => {
//           setActive(element);
//         }}
//         className={active == element ? "activee" : ""}
//       >
//         <InstitutionLookUp id={element}></InstitutionLookUp>
//       </button>
//     );
//   });
//   useEffect(() => {
//     if (active == 0) {
//       setFiltered(all);
//       return;
//     }
//     const filtered = all.filter((element) => {
//       return element.owner_institution == active;
//     });

//     setFiltered(filtered);
//   }, [active]);

//   return (
//     <div className="filter-container">

//       <button   className={active == 0 ? "activee" : ""} onClick={() => setActive(0)}>All</button>
//       {list}

//     </div>
//   );
// };

// export default Filter;
