import React, { useEffect, useState } from "react";

import { Row } from "components";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import TopList from "components/TopList";
import Timelines from "components/Timeline";
import RevenueList from "components/RevenueList";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import Graph from "components/Graph";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import ProductList from "components/Modals/ProductList";
import TimelineModal from "components/Modals/Timeline";
import SalesReport from "components/Modals/Sales";
import ProductCategory from "components/Modals/ProductCategory";
import InstitutionList from "components/Modals/InstitutionList";
import CompanyInfo from "components/Modals/CompanyInfo";
import { Badge, Box, Modal } from "@mui/material";
import Cart from "components/Modals/Cart";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import Footer from "components/Footer";
import { useGetTopSuppliersQuery } from "features/dashboard/dashboardApiSlice";
import { BounceLoader } from "react-spinners";
import { useGetCartItemsQuery } from "features/order/ordersApiSlice";
import RevenueListClient from "components/RevenueList/client";
import SalesReportClient from "components/Modals/Sales/client";

const OrgPage = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [count, setCount] = useState(0);
  const [x, setX] = React.useState();
  const { data, isLoading, isSuccess } = useGetTopSuppliersQuery();
  const editable = structuredClone(data);

  const {
    data: cartItems,
    isLoading: isLoadingg,

    isError,
    error,
  } = useGetCartItemsQuery();
  const editableCart = structuredClone(cartItems);
  useEffect(() => {
    setCount(editableCart?.length);
  }, [cartItems]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  var listSuppliers = [],
    listOrganizations = [],
    listLaboratories = [],
    listSupplierModal = [],
    listOrgModal = [],
    listLabModal = [];
  if (isSuccess) {
    for (let index = 0; index < editable?.length; index++) {
      if (index < 4) {
        listSupplierModal.push({
          desc: editable[index]?.name,
          name: editable[index]?.products_sold + " sold",
          icon: "building",
          id: editable[index]?.id,
        });
        listSuppliers.push({
          company: editable[index]?.name,
          desc: editable[index]?.products_sold + " sold",
          clicked: () => {
            chooseModal(8, editable[index].id);
            handleOpen();
          },
        });
      } else {
        listSupplierModal.push({
          desc: editable[index]?.name,
          name: editable[index]?.products_sold + " sold",
          id: editable[index]?.id,
          icon: "building",
        });
      }
    }
  }

  var listInstitutionModal = [
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
  ];
  var listRevenue = [
    { name: "Purchases", desc: "1K", icon: "tag" },
    { name: "Product", desc: "944", icon: "producthunt" },
    { name: "Items", desc: "44", icon: "category" },
    { name: "Revenue", desc: "100K ETB", icon: "moneybill" },
  ];
  var listSup = [
    { company: "Thermo", desc: "500 sold" },
    { company: "NEB", desc: "500 sold" },
    { company: "Illumina", desc: "500 sold" },
    { company: "BioPerfectus", desc: "500 sold" },
  ];
  var menu = [
    {
      title: "Items",
      dropdown: false,
      route_path: () => navigate("/organization/orderitem"),
      icon: "",
    },
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "Offers", route_path: () => navigate("/organization/offers") },
        {
          title: "Order History",
          route_path: () => navigate("/organization/order"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/organization/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
    {
      title: "",
      route_path: () => {
        chooseModal(13);
        handleOpen();
      },
      icon: faCartShopping,
    },
  ];

  function chooseModal(idx, id) {
    switch (idx) {
      case 1:
        setX(<ProductList />);
        break;
      case 2:
        setX(<ProductList />);
        break;
      case 3:
        setX(<ProductList />);
        break;
      case 4:
        setX(<TimelineModal />);
        break;
      case 5:
        setX(<SalesReportClient />);
        break;
      case 6:
        setX(<ProductList />);
        break;
      case 7:
        setX(<ProductCategory />);
        break;
      case 8:
        setX(<CompanyInfo id={id} limited={true} />);
        break;

        break;
      case 10:
        setX(
          <InstitutionList
            title="Total Suppliers"
            subTitle={editable?.length + " Total Suppliers"}
            data={listSupplierModal}
          />
        );
        break;
      case 11:
        setX(
          <InstitutionList
            title="Total Organizations"
            subTitle="45 Total Organizations"
            data={listInstitutionModal}
          />
        );
        break;
      case 12:
        setX(
          <InstitutionList
            title="Total Laboratories"
            subTitle="45 Total Laboratories"
            data={listInstitutionModal}
          />
        );
        break;
      case 13:
        setX(<Cart />);
        break;
      default:
        setX(<CompanyInfo />);
    }
  }
  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "scroll" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <CompanyInfo />}
      </Modal>
      <Header
        homePath="/organization"
        menus={menu}
        cart={
          <div
            onClick={() => {
              chooseModal(13);
              handleOpen();
            }}
          >
            <Badge
              className="px-[auto] mx-2"
              color="primary"
              badgeContent={count}
            >
              <ShoppingCartIcon htmlColor="#ffffff" />
            </Badge>
          </div>
        }
      />
      {isLoading || isLoadingg ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "100vh",
          }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <>
          <Row class="mx-[auto] w-[75%] sm:w-[80%] mt-[20px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <TopList
              icon="building"
              entries={listSuppliers}
              title="Top 4 Suppliers"
              subtitle={editable?.length + " Total Suppliers"}
              seeMore={() => {
                chooseModal(10);
                handleOpen();
              }}
              // see={false}
              //clickable={false}
            />
            <div class="xl:ml-[26px]">
              <RevenueListClient
                title="Spendings Summary"
                seeMore={() => {
                  chooseModal(5);
                  handleOpen();
                }}
              />
            </div>
            <div>
              <Timelines
                seeMore={() => {
                  chooseModal(4);
                  handleOpen();
                }}
              />
            </div>
          </Row>
          <div class="w-[75%] pb-[5rem] sm:w-[80%] mx-[auto] md:w-[80%] lg:w-[80%] xl:w-[80%] mt-[20px] md:mt-[20px] lg:mt-[20px] xl:mt-[20px]">
            <Graph role={"client"} />
          </div>
        </>
      )}

      <Footer />
    </>
  );
};
export default OrgPage;
