import { ShoppingCart } from "@material-ui/icons";
import { Sell } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { Column } from "components/Column";
import Graph from "components/Graph";
import GraphSales from "components/Graph/Sales";
import RevenueElement from "components/RevenueElement";
import { Row } from "components/Row";
import StatBoxes from "components/StatBoxes";
import { Text } from "components/Text";
import { useGetOrderStatsQuery } from "features/dashboard/dashboardApiSlice";
import React from "react";
import { BounceLoader } from "react-spinners";

function SalesReportSupplier({ wid, totalSales }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { wid },
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    px: 5,
    py: 5,
  };
  const { data: orders, isLoading, isSuccess } = useGetOrderStatsQuery();
  const editable = structuredClone(orders);
  console.log(orders);
  var percent = 0,
    totalOrders = 0;
  if (isSuccess) {
    totalOrders =
      parseInt(orders?.total_completed_transactions) +
      parseInt(orders?.total_incomplete_orders);
    percent =
      (parseInt(orders?.total_completed_transactions) / totalOrders) * 100;
    percent = Math.floor(percent);
  }
  return (
    <Box
      className="w-[80%] sm:w-[55%] lg:w-[45%] xl:w-[50%] h-[75vh]"
      sx={style}
    >
      <div style={{ overflow: "scroll", height: "65vh" }}>
        <Column className="">
          <Text className="not-italic text-gray_900 w-[auto]" variant="h3">
            Sales Summary
          </Text>
          <Text
            className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
            variant="body2"
          ></Text>
          {isLoading ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                height: "50px",
              }}
            >
              <BounceLoader
                color="#283660"
                // className="bg-green_400"
              />
            </Box>
          ) : (
            <>
              <div className="mt-[10px] grid grid-cols-2 xl:grid-cols-3 sm:gap-10">
                <RevenueElement
                  icon={
                    <Sell
                      htmlColor="#ffffff"
                      className="my-3"
                      fontSize="large"
                      alt="user"
                    />
                  }
                  title="Total Sale"
                  amount={orders?.total_sale + "ETB"}
                  desc={orders?.total_products_sold + " Products Sold"}
                />
                <RevenueElement
                  icon={
                    <Sell
                      htmlColor="#ffffff"
                      className="my-3"
                      fontSize="large"
                      alt="user"
                    />
                  }
                  title="Products Variety"
                  amount={orders?.total_ref_items + " Distinct Items"}
                  desc={orders?.total_ref_kits + " Distinct Kits"}
                />
                <RevenueElement
                  icon={
                    <ShoppingCart
                      htmlColor="#ffffff"
                      className="my-3"
                      fontSize="large"
                      alt="user"
                    />
                  }
                  title="Orders"
                  amount={totalOrders + " Orders"}
                  desc={percent + "% Transactions Completed"}
                />
              </div>
              <div className="mt-[20px] ml-[15px]">
                <GraphSales height={250} width={500} />
              </div>
            </>
          )}
        </Column>
      </div>
    </Box>
  );
}

export default SalesReportSupplier;
