// import React, { useEffect, useState } from "react";
// import { Box, Modal } from "@mui/material";
// import { Column, Row, Text } from "components";
// import { useNavigate } from "react-router-dom";
// import Card from "../../../components/Card/index";
// import Header from "components/Header/index.jsx";
// import { faBoxesStacked, faUser } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import ReviewItemsModal from "components/Modals/ReviewItems";
// import AddGlobalItem from "components/Modals/CreateGlobalItem";
// import { logOut } from "features/auth/authSlice";
// import { useDispatch } from "react-redux";
// import { BounceLoader } from "react-spinners";
// import {
//   useGetGlobalItemsClientQuery,
//   useGetGlobalItemsOfficerQuery,
// } from "features/item/clientItemsSlice";
// import Paginationn from "../../../components/Pagination/index";
// import SearchBar from "components/Search";
// import Footer from "components/Footer";
// import Filter from "components/Filter";

// const ReviewItemsPage = () => {
//   const navigate = useNavigate();
//   const [x, setX] = React.useState();
//   const [filtered, setFiltered] = React.useState([]);
//   const [active, setActive] = React.useState(0);
//   const [open, setOpen] = React.useState(false);
//   const dispatch = useDispatch();

//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   const {
//     data: records,
//     isLoading,
//     refetch,
//     isSuccess,
//     isFetching,
//     error,
//   } = useGetGlobalItemsOfficerQuery();
//   console.log(records);
//   const suppliers = [];
//   const result = records?.filter((record) => {
//     return record.soyo_margin_adjusted == true;
//   });

//   result?.forEach((element) => {
//     if (!suppliers.includes(element.owner_institution)) {
//       suppliers.push(element.owner_institution);
//     }
//   });
//   console.log(suppliers);

//   const editable = structuredClone(result);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [dataPerPage] = useState(6);
//   const [loading, setLoading] = useState(true);
//   const [myApi, setMyApi] = useState([]);
//   const [data, setData] = useState([]); // add your data to here
//   const [searchQuery, setSearchQuery] = useState("");
//   useEffect(async () => {
//     if (isSuccess) {
//       setFiltered(editable);
//       setData(filtered);

//       let myApii;
//       if (searchQuery == "") {
//         myApii = filtered;
//       } else {
//         myApii = filtered.filter(
//           (item) =>
//             item.name.toLowerCase().includes(searchQuery) ||
//             item.product.type.toLowerCase().includes(searchQuery)
//         );
//       }
//       console.log("myApii", myApii);
//       setMyApi(renderData(myApii));
//       // setFiltered(renderData(myApii));
//       setLoading(false);
//     }
//   }, [records, isSuccess, searchQuery, active]);
//   const indexOfLastData = currentPage * dataPerPage;
//   const indexOfFirstData = indexOfLastData - dataPerPage;
//   const currentData = myApi?.slice(indexOfFirstData, indexOfLastData);
//   const paginate = (pageNumber) => setCurrentPage(pageNumber);
//   const renderData = (data) => {
//     return data?.map((item, idx) => {
//       return (
//         <Card
//           modal={false}
//           icon={
//             <FontAwesomeIcon
//               type="outline"
//               className="px-[auto]"
//               color="#ffffff"
//               fontSize={30}
//               width={40}
//               icon={faBoxesStacked}
//             />
//           }
//           row11={item.name}
//           row21="200Qty"
//           // row12={item.description}
//           row22="49ETB"
//           row41={item.quantity + "QTY"}
//           row42={item.product.type}
//           button2name="Review"
//           button2action={() => {
//             {
//               chooseModal(1, item);
//               handleOpen();
//             }
//           }}
//         />
//       );
//     });
//   };
//   var menu = [
//     {
//       title: "Orders",
//       dropdown: true,
//       entries: [
//         { title: "View Orders", route_path: () => navigate("/officer/orders") },
//         {
//           title: "Order History",
//           route_path: () => navigate("/officer/orderhistory"),
//         },
//       ],
//       icon: "",
//     },
//     {
//       title: "Items",
//       dropdown: true,
//       entries: [
//         {
//           title: "Add Global Item",
//           route_path: () => {
//             chooseModal(2);
//             handleOpen();
//           },
//         },
//         {
//           title: " Global Inventory",
//           route_path: () => navigate("/officer/globalinventory"),
//         },
//         {
//           title: " Reference List",
//           route_path: () => navigate("/officer/referenceList"),
//         },

//         {
//           title: "Review Items",
//           route_path: () => navigate("/officer/reviewitems"),
//         },
//       ],
//       icon: "",
//     },
//     {
//       dropdown: true,
//       title: "",
//       entries: [
//         {
//           title: "Account Setting",
//           route_path: () => {
//             navigate("/officer/account");
//           },
//         },
//         {
//           title: "Log Out",
//           route_path: () => {
//             dispatch(logOut());
//             navigate("/");
//           },
//         },
//       ],
//       icon: faUser,
//     },
//   ];
//   function chooseModal(idx, data) {
//     switch (idx) {
//       case 1:
//         setX(<ReviewItemsModal data={data} />);
//         break;
//       case 2:
//         setX(<AddGlobalItem />);
//         break;
//     }
//   }
//   const none = <Text>No items to review.</Text>;

//   return (
//     <>
//       <Modal
//         open={open}
//         sx={{ overflow: "scroll" }}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         {x ?? <AddGlobalItem />}
//       </Modal>
//       <Header homePath="/officer" menus={menu} />
//       {isLoading || loading ? (
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             height: "100vh",
//           }}
//         >
//           <BounceLoader color="#283660" />
//         </Box>
//       ) : (
//         <>
//           <Column className="mx-[auto] pb-[5rem] w-[90%] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
//             <Row className="mb-[1%] justify-between">
//               <Text variant="h1" className=" not-italic text-gray_900">
//                 Review Items
//               </Text>

//               <SearchBar
//                 onChange={(event) =>
//                   setSearchQuery(event.target.value.toLowerCase())
//                 }
//               />
//             </Row>
//             <Row className="mb-[1%] justify-between">
//               <Filter
//                 all={editable}
//                 setFiltered={setFiltered}
//                 active={active}
//                 setActive={setActive}
//                 suppliers={suppliers}
//               />
//             </Row>
//             {myApi == undefined || myApi?.length == 0 ? (
//               none
//             ) : (
//               <>
//                 {currentData}
//                 <Paginationn
//                   dataPerPage={dataPerPage}
//                   totaldata={myApi.length}
//                   paginate={paginate}
//                 />
//               </>
//             )}
//           </Column>
//           <Footer />
//         </>
//       )}
//     </>
//   );
// };

// export default ReviewItemsPage;
import React, { useEffect, useState } from "react";
import { Box, MenuItem, Modal, Select } from "@mui/material";
import { Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import { faBoxesStacked, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReviewItemsModal from "components/Modals/ReviewItems";
import AddGlobalItem from "components/Modals/CreateGlobalItem";
import { logOut } from "features/auth/authSlice";
import { useDispatch } from "react-redux";
import { BounceLoader } from "react-spinners";
import {
  useGetGlobalItemsClientQuery,
  useGetGlobalItemsOfficerQuery,
} from "features/item/clientItemsSlice";
import Paginationn from "components/Pagination";
import SearchBar from "components/Search";
import Footer from "components/Footer";
import Filter from "components/Filter";

const ReviewItemsPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    data: records,
    isLoading,
    refetch,
    isSuccess,
    isFetching,
    error,
  } = useGetGlobalItemsOfficerQuery();
  const result = records?.filter((record) => {
    return record.soyo_price == 0;
  });
  var suppliers = [];
  result?.forEach((element) => {
    if (!suppliers.includes(element.owner_institution)) {
      suppliers.push(element.owner_institution);
    }
  });
  const [filtered, setFiltered] = React.useState([]);
  const [active, setActive] = React.useState(0);
  const editable = structuredClone(result);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(6);
  const [loading, setLoading] = useState(true);
  const [myApi, setMyApi] = useState([]);
  const [data, setData] = useState([]); // add your data to here
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(async () => {
    if (isSuccess) {
      setData(editable);
      //setFiltered(data);
      let myApii;
      if (searchQuery == "") {
        myApii = filtered;
      } else {
        myApii = filtered.filter(
          (item) =>
            item.name.toLowerCase().includes(searchQuery) ||
            item.product.type.toLowerCase().includes(searchQuery)
        );
      }
      setMyApi(renderData(myApii));
      setLoading(false);
    }
  }, [records, searchQuery, filtered]);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = myApi?.slice(indexOfFirstData, indexOfLastData);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const renderData = (data) => {
    return data?.map((item, idx) => {
      return (
        <Card
          modal={false}
          icon={
            <FontAwesomeIcon
              type="outline"
              className="px-[auto]"
              color="#ffffff"
              fontSize={30}
              width={40}
              icon={faBoxesStacked}
            />
          }
          row11={item.name}
          row21="200Qty"
          // row12={item.description}
          row22="49ETB"
          row41={item.quantity + "QTY"}
          row42={item.product.type}
          button2name="Review"
          button2action={() => {
            {
              chooseModal(1, item);
              handleOpen();
            }
          }}
        />
      );
    });
  };

  var menu = [
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "View Orders", route_path: () => navigate("/officer/orders") },
        {
          title: "Order History",
          route_path: () => navigate("/officer/orderhistory"),
        },
      ],
      icon: "",
    },
    {
      title: "Items",
      dropdown: true,
      entries: [
        {
          title: "Add Global Item",
          route_path: () => {
            chooseModal(2);
            handleOpen();
          },
        },
        {
          title: " Global Inventory",
          route_path: () => navigate("/officer/globalinventory"),
        },
        {
          title: " Reference List",
          route_path: () => navigate("/officer/referenceList"),
        },

        {
          title: "Review Items",
          route_path: () => navigate("/officer/reviewitems"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/officer/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];
  function chooseModal(idx, data) {
    switch (idx) {
      case 1:
        setX(<ReviewItemsModal data={data} />);
        break;
      case 2:
        setX(<AddGlobalItem />);
        break;
    }
  }
  const none = <Text>No items to review.</Text>;

  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "scroll" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <AddGlobalItem />}
      </Modal>
      <Header homePath="/officer" menus={menu} />
      {isLoading || loading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "100vh",
          }}
        >
          <BounceLoader color="#283660" />
        </Box>
      ) : (
        <>
          <Column className="mx-[auto] pb-[5rem] w-[90%] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
            <Row className="mb-[1%] justify-between">
              <Text variant="h1" className=" not-italic text-gray_900">
                Review Items
              </Text>
            </Row>
            <Row className="mb-[1%] justify-between">
              <SearchBar
                onChange={(event) =>
                  setSearchQuery(event.target.value.toLowerCase())
                }
              />
              <Filter
                all={editable}
                setFiltered={setFiltered}
                active={active}
                setActive={setActive}
                suppliers={suppliers}
              />
            </Row>
            {myApi == undefined || myApi?.length == 0 ? (
              none
            ) : (
              <>
                {currentData}
                <Paginationn
                  dataPerPage={dataPerPage}
                  totaldata={myApi?.length}
                  paginate={paginate}
                />
              </>
            )}
          </Column>
          <Footer />
        </>
      )}
    </>
  );
};

export default ReviewItemsPage;
