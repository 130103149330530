import { Box } from "@mui/material";
import { Column } from "components/Column";
import BasicPagination from "components/Pagination";
import ListItem from "components/ListItem";
import { Text } from "components/Text";
import React from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import { Row } from "components/Row";
import { data } from "autoprefixer";
import { useGetReferenceKitQuery } from "features/item/referenceKitApiSlice";
import KitItems from "components/KitItems";
import { BounceLoader } from "react-spinners";

function KitInfo({ id }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,

    py: 5,
    px: 5,
  };
  const { data, isLoading, isSuccess, refetch } = useGetReferenceKitQuery(id);
  //   let list= data.map((item,index)=>{
  //     return   <ListItem key={index} desc={item.company} name='' icon='producthunt' />
  //   });
  return (
    <Box className="w-[80%] md:w-[600px] h-[75vh]" sx={style}>
      <div style={{ overflow: "scroll", height: "65vh" }}>
        {!isSuccess ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "50px",
            }}
          >
            <BounceLoader
              color="#283660"
              // className="bg-green_400"
            />
          </Box>
        ) : (
          <Column>
            <Text className="not-italic text-gray_900 w-[auto]" variant="h3">
              {data && data?.name}
            </Text>
            <Text
              className="mb-[10px] sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
              variant="body2"
            >
              List of Items in this kit
              {/* {desc} */}
            </Text>
            {data?.items?.map((item, idx) => {
              return <KitItems id={item} />;
            })}
            {/* <Text
          className="not-italic text-gray_900 w-[auto]"
          variant="h4"
        >
        Sales
        </Text>
        <Text
          className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
          variant="body3"
        >
        30 Total Items
        </Text>
        <div className='my-[10px]'>
        <Row className='grid grid-cols-1 md:grid-cols-2'> 
       {list}
       </Row>
        </div>
   <BasicPagination /> */}
          </Column>
        )}
      </div>
    </Box>
  );
}

KitInfo.propTypes = {
  id: PropTypes.string,
};

export default KitInfo;
