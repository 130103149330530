import React from "react";
import PropTypes from "prop-types";
import {
  faUser,
  faDownload,
  faCheckCircle,
  faBoxesStacked,
} from "@fortawesome/free-solid-svg-icons";

import { Column, Row, Img, Text, Button } from "components";
import { useNavigate } from "react-router-dom";
import { Icon } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardForProforma = ({
  icon,
  row11,
  row12,
  button1name,
  button1Icon,
  button1color,
  button2name,
  button2color,
  button1action,
  button2action,
  button2Icon,
  ...restProps
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          borderRadius: 10,
          backgroundColor: "rgba(40, 54, 96, 0.2)",
        }}
        className="w-[90%] inline-block h-[60px] sm:h-[60px] md:h-[60px] lg:h-[70px] xl:h-[80px]"
      >
        <Row className="mx-2 sm:mx-3">
          <div className="w-[13%] sm:w-[15%] mr-1 mt-[5%] sm:mt-[3%] md:mt-[4%] lg:mt-[5%]">
            {
              <FontAwesomeIcon
                type="outline"
                className="px-[auto]"
                color="#ffffff"
                fontSize={30}
                width={40}
                icon={faBoxesStacked}
              />
            }
          </div>
          <Column className="grid grid-rows-2 mt-[10px] xl:mt-[15px] w-[62%] sm:w-[55%]">
            <Text className="not-italic text-[20px] text-gray_900" variant="h6">
              {row11}
            </Text>
            <Text className="text-bluegray_300" variant="h6">
              {row12}
            </Text>
          </Column>

          {button2name == ""  ? (
            <div className="w-[30%] sm:w-[40%]">
              {/* {button2Icon == null ? ( */}
              <Button
                variant="None"
                className="ml-[auto] h-[40px] w-[90%] mt-[8px] md:mt-[10px] lg:mt-[15px]"
                onClick={button2action}
                children={
                  <FontAwesomeIcon
                    type="outline"
                    className="px-[auto]"
                    color={button2color ? button2color : "#ffffff"}
                    fontSize={30}
                    width={20}
                    icon={button2Icon}
                  />
                }
              ></Button>
              
              
            </div>
          ) : (
            <div className="w-[20%] sm:w-[20%]">
              <Button
                variant={button2color ? button2color : "FillIndigo900cc"}
                className="ml-[auto] h-[40px] w-[80%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px] "
                onClick={button2action}
                children={<Text variant='body1'>{button2name}</Text>}
              ></Button>
            </div>
          )}
          {button1name == "" ? (
            <div className="w-[15%] sm:w-[20%]">
              {/* {button2Icon == null ? ( */}
              <Button
                variant={button1color ? button1color : "FillIndigo900cc"}
                className="ml-[auto] h-[40px] w-[90%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px]"
                onClick={button1action}
                children={
                  <FontAwesomeIcon
                    type="outline"
                    className="px-[auto]"
                    color={"#ffffff"}
                    fontSize={30}
                    width={20}
                    icon={faDownload}
                  />
                }
              ></Button>
            </div>
          ) : (
            <div className="w-[20%] sm:w-[20%]">
              <Button
                variant={button1color ? button1color : "FillIndigo900cc"}
                className="ml-[auto] h-[40px] w-[80%] mt-[8px] md:mt-[10px] mt-[15px] md:mt-[10px] lg:mt-[15px]"
                onClick={button1action}
                children={<Text variant='body1'>{button1name}</Text>}
              ></Button>
            </div>
          )}
        </Row>
      </div>
    </>
  );
};
CardForProforma.propTypes = {
  icon: PropTypes.object,
  row11: PropTypes.string,
  row12: PropTypes.string,
  button1name: PropTypes.string,
  button1color: PropTypes.string,
  button2name: PropTypes.string,
  button2color: PropTypes.string,
  button1action: PropTypes.func,
  button2action: PropTypes.func,
  button2Icon: PropTypes.object,
  button1Icon: PropTypes.object,
};
CardForProforma.defaultProps = {
  icon: (
    <FontAwesomeIcon
      type="outline"
      className="px-[auto]"
      color="#ffffff"
      fontSize={30}
      width={40}
      icon={faUser}
    />
  ),
  row11: "",
  row12: "",
  button1name: "",
  button1Icon: { faDownload },
  button2Icon: { faCheckCircle },
  button1color: "FillIndigo900cc",
  button2name: "",
  button2color: "FillIndigo900cc",
  button1action: () => {
  },
  button2action: () => {
  },
};
export default CardForProforma;
