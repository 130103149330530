import { Text } from "components/Text";
import { useGetInstitutionQuery } from "features/institution/institutionApiSlice";
import { useGetReferenceItemQuery } from "features/item/referenceItemApiSlice";
import React from "react";

const ItemsLookUp = (id) => {
  const { data } = useGetReferenceItemQuery(id.id);
  return (
    <Text className="text-gray_900" variant="h6">
      {data?.name}
    </Text>
  );
};

export default ItemsLookUp;
