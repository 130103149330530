import React from "react";
const variantClasses = {
  h1: "font-normal text-[21px] sm:text-[21px] md:text-[23px] lg:text-[25px] xl:text-[29px] md:text-[22px] 3xl:text-[39px]",
  h2: "font-normal text-[19px] sm:text-[19px] md:text-[21px] lg:text-[22px] xl:text-[26px] md:text-[19px] 3xl:text-[36px]",
  h3: "font-normal text-[17px] sm:text-[17px] md:text-[18px] lg:text-[20px] xl:text-[23px] md:text-[17px] 3xl:text-[31px]",
  h4: "font-normal text-[18px] sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[17px] md:text-[14px] 3xl:text-[24px]",
  h5: "font-normal text-[14px] sm:text-[12px] md:text-[13px] lg:text-[15px] xl:text-[16px] md:text-[12px] 3xl:text-[21px]",
  h6: "font-normal text-[14px] sm:text-[11px] md:text-[12px] lg:text-[14px] xl:text-[15px] md:text-[13px] 3xl:text-[20px]",
  body1:
    "font-medium text-[13px] sm:text-[11px] md:text-[12px] lg:text-[13px] xl:text-[14px] 3xl:text-[19px]",
  body2: "font-normal text-[12px] md:text-[10px] xl:text-[15px] text-[14px] 3xl:text-[16px] lg:text-[10px]",
  body3: "font-normal text-[11px] md:text-[9px] xl:text-[11px] 3xl:text-[15px] lg:text-[9px]",
  body4: "font-normal text-[10px] md:text-[8px] xl:text-[10px] text-[12px] 3xl:text-[14px] lg:text-[8px]",
  body5: "font-normal text-[9px] md:text-[8px] 3xl:text-[12px] lg:text-[7px] xl:text-[8px]",
};
const Text = ({ children, className, variant, as, ...restProps }) => {
  const Component = as || "span";
  return (
    <Component
      className={`${className} ${variantClasses[variant]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
