import { Box } from "@mui/material";
import { Column } from "components/Column";
import BasicPagination from "components/Pagination";
import ListItem from "components/ListItem";
import { Text } from "components/Text";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SelectLocation from "../../Mappp/index";
import PropTypes from "prop-types";
import { Row } from "components/Row";
import { useFormik, useFormikContext } from "formik";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { BounceLoader } from "react-spinners";

const libraries = ["places"];

function OrderInfo({ info, name }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,

    p:5,
  };

  const mapRef = useRef();

  const onLoad = useCallback((map) => (mapRef.current = map), []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDJGH71uPHAv_QmPcZ3DzhfpEkyDcoL9ao",
    libraries,
  });

  //  let list= data.map((item,index)=>{
  //       return   <ListItem key={index} desc={item.company} name='' icon='producthunt' />
  //     });
  return (
    <Box className="w-[80%] md:w-[600px] h-[75vh]" sx={style}>
      <div style={{ overflow: "scroll", height: "65vh" }}>
        <Column>
          <Text className="not-italic text-gray_900 w-[auto]" variant="h3">
            Delivery Location
          </Text>
          <Text
            className="sm:my-[1px] md:my-[2px] lg:my-[3px] xl:my-[4px] 2xl:my-[5px] 3xl:my-[6px] not-italic text-bluegray_300 w-[auto]"
            variant="body2"
          >
            {name}
          </Text>

          {/* <div className="my-[10px]" style={{ height: "200px", width: "100%" }}> */}
          <div>
            {!isLoaded ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  height: "50px",
                }}
              >
                <BounceLoader
                  color="#283660"
                  // className="bg-green_400"
                />
              </Box>
            ) : (
              <GoogleMap
                mapContainerClassName="map-container mt-[3%]"
                mapContainerStyle={{
                  width: "100%",
                  height: "300px",
                }}
                zoom={15}
                options={{ streetViewControl: false }}
                onLoad={onLoad}
                center={{
                  lat: info?.latitude,
                  lng: info?.longitude,
                }}
                onClick={(e) => {}}
              >
                <Marker
                  // icon={markerImage}
                  style={{ width: "24px", height: "24px" }}
                  position={{
                    lat: info?.latitude,
                    lng: info?.longitude,
                  }}
                />
              </GoogleMap>
            )}
            {/* <SelectLocation disabled={false} /> */}
          </div>

          {/* <Text className="not-italic text-gray_900 w-[auto]" variant="h4">
          Sales
        </Text>
        <Text
          className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
          variant="body3"
        >
          30 Total Items
        </Text>
        <div className="my-[10px]">
          <Row className="grid grid-cols-1 md:grid-cols-2">{list} </Row>
        </div>  */}
        </Column>
      </div>
    </Box>
  );
}

OrderInfo.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  desc: PropTypes.string,
};
OrderInfo.defaultProps = {
  data: [
    { company: "Thermo", desc: 500 },
    { company: "NEB", desc: 500 },
    { company: "NEB", desc: 500 },
    { company: "NEB", desc: 500 },
  ],
  title: "International Clinical Laboratories",
  desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
};

export default OrderInfo;
