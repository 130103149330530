import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import Search from "./Search";
import { Box, Skeleton } from "@mui/material";
//import withScriptjs from "react-google-maps/lib/async/withScriptjs";
//import withGoogleMap from "react-google-maps/lib/async/withGoogleMap";
import { useState, useMemo, useCallback, useRef, useEffect } from "react";
import { useFormikContext } from "formik";
// import useLocalStorage from "../../hooks/useLocalStorage";
import markerImage from "../../components/marker2.png";

const libraries = ["places"];

const SelectLocation = ({ disabled = false, placeholder="Type Here" }) => {
  const apiKey = "AIzaSyDJGH71uPHAv_QmPcZ3DzhfpEkyDcoL9ao";
  const [apiLoaded, setApiLoaded] = useState(true);

  if (!apiLoaded) {
    return (
      <Skeleton animation="wave" sx={{ width: "100%", height: "500px" }} />
    );
  }

  return <SearchWithMap placeholder={placeholder}  apiKey={apiKey} disabled={disabled} />;
};

const SearchWithMap = ({placeholder, apiKey, disabled = false }) => {
  const [markerActive, setMarkerActive] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const [coordinates, setCoordinates] = useState();

  const mapRef = useRef();
  const center = useMemo(
    () => ({
      lat: values["location_lat"] ? values["location_lat"] : 8.99,
      lng: values["location_lon"] ? values["location_lon"] : 38.78,
    }),
    []
  );
  const options = useMemo(
    () => ({
      //   disableDefaultUI: true,
      // clickableIcons: false,
    }),
    []
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  useEffect(() => {
    if (
      values["location_name"] &&
      values["location_lat"] &&
      values["location_lon"]
    ) {
      setCoordinates({
        lat: values["location_lat"] ? values["location_lat"] : 8.99,
        lng: values["location_lon"] ? values["location_lon"] : 38.78,
      });
      setMarkerActive(true);
    }
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });
  if (!isLoaded)
    return <Skeleton animation="wave" sx={{ width: "90%", height: "500px" }} />;

  //const myMap = withScriptjs(withGoogleMap((props) => <GoogleMap />));

  return (
    <Box sx={{ width: "93%" }}>
    <Search
      placeholder={placeholder}
        setLocation={(pos, address) => {
          if (mapRef.current) {
            setFieldValue("location_lat", pos.lat);
            setFieldValue("location_lon", pos.lng);
            setMarkerActive(true);

            setFieldValue("location_name", address);
            mapRef.current.panTo(pos);
          }
        }}
        initalLocation={
          values["location_name"] ? values["location_name"] : null
        }

        disabled={disabled}
      />
      <GoogleMap
        mapContainerClassName="map-container"
        mapContainerStyle={{
          width: "100%",
          height: "300px",
        }}
        zoom={15}
        options={{ streetViewControl: false }}
        onLoad={onLoad}
        center={center}
        onClick={(e) => {}}
      >
        {markerActive && (
          <Marker
            // icon={markerImage}
            style={{ width: "24px", height: "24px" }}
            position={{
              lat: values["location_lat"] ? values["location_lat"] : 8.99,
              lng: values["location_lon"] ? values["location_lon"] : 38.78,
            }}
          />
        )}
      </GoogleMap>
    </Box>
  );
};

export default SelectLocation;
