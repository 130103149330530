import { apiSlice } from "../../app/api/apiSlice";

export const globalItemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createItem: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/inventory/supplier/items/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Item"],
    }),
    createKit: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/inventory/supplier/kits/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Item"],
    }),

    getItems: builder.query({
      query: () => "inventory/supplier/items/",
      keepUnusedDataFor: 5,
      providesTags: ["Item"],
    }),
    getKits: builder.query({
      query: () => "inventory/supplier/kits/",
      keepUnusedDataFor: 5,
      providesTags: ["Item"],
    }),
    updateItem: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/inventory/supplier/items/${credentials.id}/`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["Item"],
    }),
    updateKit: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/inventory/supplier/kits/${credentials.id}/`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["Item"],
    }),
    getItem: builder.query({
      query: (id) => `/inventory/supplier/items/'${id}'/`,
      keepUnusedDataFor: 5,
      providesTags: ["Item"],
    }),
    deleteItem: builder.mutation({
      query: (id) => ({
        // mode: 'cors',
        url: `/inventory/supplier/items/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Item"],
    }),
    deleteKit: builder.mutation({
      query: (id) => ({
        // mode: 'cors',
        url: `/inventory/supplier/kits/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Item"],
    }),
  }),
});

export const {
  useCreateItemMutation,
  useGetItemQuery,
  useGetItemsQuery,
  useGetKitsQuery,
  useDeleteItemMutation,
  useUpdateItemMutation,
  useUpdateKitMutation,
  useDeleteKitMutation,
  useCreateKitMutation,
} = globalItemApiSlice;
