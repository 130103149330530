import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/accounts/login/`,
        method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json'
        //    },
        body: {
          username: credentials.username,
          password: credentials.password,
        },
      }),
      invalidatesTags: ["Login"],
    }),
    checkPassword: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/users/check-password/`,

        method: "POST",
        body: {
          password: credentials.password,
        },
      }),
    }),

    getInfo: builder.query({
      query: () => "/users/info/",
      keepUnusedDataFor: 0,
      // providesTags: ["Login"],
    }),
    forgotPasswordSendEmail: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/users/password_reset/",
        method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json'
        //    },
        body: credentials,
      }),
    }),
    forgotPasswordReset: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "users/password_reset/confirm/",
        method: "POST",
        //     headers: {
        //         'Content-Type': 'application/json'
        //    },
        body: credentials,
      }),
    }),
    resetPassword: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/users/password-set/?${credentials.param}`,

        method: "PUT",
        body: {
          password: credentials.password,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/users/${credentials.id}/`,

        method: "PATCH",
        body: {
          password: credentials.password,
        },
      }),
    }),
  }),
});
export const {
  useLoginMutation,
  useResetPasswordMutation,
  useForgotPasswordSendEmailMutation,
  useForgotPasswordResetMutation,
  useGetInfoQuery,
  useChangePasswordMutation,
  useCheckPasswordMutation,
} = authApiSlice;
