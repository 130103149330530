import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useDispatch } from "react-redux";
import { setCredentials, logOut } from "../../features/auth/authSlice";
const noAuthEndpoints = ["login"];
// const dispatch = useDispatch();

const baseQuery = fetchBaseQuery({
  baseUrl: "https://test.api.soyo.testserver.awuraplc.org",
  //baseUrl: "https://api.soyo.soyoafricabiotech.com",
  // baseUrl: '65.21.62.178:27696',
  credentials: "include",
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = localStorage.getItem("userToken");
    // const token = "a";
    if (token && !noAuthEndpoints.includes(endpoint)) {
      headers.set("authorization", `Token ${token}`);
    } else {
      headers.delete("Authorization");
    }
    // headers.set("Content-Type", "application/json")

    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  const token = localStorage.getItem("userToken");
  // if (token) {
  // console.log("re auth");
  let result = await baseQuery(args, api, extraOptions);
  // console.log(result);

  if (
    result?.error?.status === 401 &&
    (result?.error?.data?.error === "Invalid or Inactive Token" ||
      result?.error?.data?.error === "Token has expired")
  ) {
    api.dispatch(logOut());
    window.location.replace("/");
    // useDispatch(logOut());
    return;
  }
  return result;
  // } else return;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "User",
    "GlobalItem",
    "GlobalKit",
    "Item",
    "Institution",
    "Cart",
    "Forecast",
    "Login",
  ],
  endpoints: (builder) => ({}),
});
