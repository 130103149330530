import { apiSlice } from "../../app/api/apiSlice";

export const globalItemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createGlobalItemClient: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/inventory/global/products/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["GlobalItem"],
    }),
    updateSoyoMargin: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/inventory/global/current/${credentials.id}/`,
        method: "PATCH",
        body: {
          soyo_price: credentials.soyo_price,
          soyo_margin_adjusted: credentials.soyo_margin_adjusted,
        },
      }),
      invalidatesTags: ["GlobalItem"],
    }),
    getGlobalItemsClient: builder.query({
      query: () => "inventory/global/products/",
      keepUnusedDataFor: 5,
      providesTags: ["GlobalItem"],
    }),
    getGlobalItemsOfficer: builder.query({
      query: () => "inventory/global/current/",
      keepUnusedDataFor: 5,
      providesTags: ["GlobalItem"],
    }),

    getGlobalItemClient: builder.query({
      query: (id) => "/inventory/global/products/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["GlobalItem"],
    }),
  }),
});

export const {
  useCreateGlobalItemClientMutation,
  useGetGlobalItemClientQuery,
  useGetGlobalItemsClientQuery,
  useUpdateSoyoMarginMutation,
  useGetGlobalItemsOfficerQuery,
} = globalItemApiSlice;
