import { apiSlice } from "../../app/api/apiSlice";

export const institutionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createInstitution: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/institutions/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Institution"],
    }),
    createClient: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/clients/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Institution"],
    }),
    getClients: builder.query({
      query: () => "/clients/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    createSupplier: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/suppliers/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Institution"],
    }),
    getSuppliers: builder.query({
      query: () => "/suppliers/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    updateInstitution: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/institutions/${credentials.id}/`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["Institution"],
    }),
    deleteInstitution: builder.mutation({
      query: (id) => ({
        url: `/institutions/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Institution"],
    }),
    getInstitutions: builder.query({
      query: () => "/institutions/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getInstitution: builder.query({
      query: (id) => "/institutions/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
  }),
});

export const {
  useCreateInstitutionMutation,
  useCreateClientMutation,
  useCreateSupplierMutation,
  useGetInstitutionsQuery,
  useGetInstitutionQuery,
  useDeleteInstitutionMutation,
  useUpdateInstitutionMutation,
} = institutionApiSlice;
