import { Box, Modal } from "@mui/material";
import { Column } from "components/Column";
import ListItem from "components/ListItem";
import { Text } from "components/Text";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paginationn from "components/Pagination";
import CompanyInfo from "../CompanyInfo";
import { Row } from "components/Row";
import SearchBar from "components/Search";

function InstitutionList({ title, subTitle, data, wid }) {
  const [loading, setLoading] = useState(true);
  const [myApi, setMyApi] = useState([]);
  const [dataa, setDataa] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [id, setId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(6);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = myApi?.slice(indexOfFirstData, indexOfLastData);
  const paginate = (pageNumber) => {setCurrentPage(pageNumber);};

  useEffect(() => {
    setDataa(data);
    let myApii;
    if (searchQuery == "") {
      myApii = data;
    } else {
      myApii = data.filter((item) =>
        item.desc.toLowerCase().includes(searchQuery)
      );
    }
    setMyApi(renderData(myApii));
    setLoading(false);
  }, [data, searchQuery]);
  const renderData = (dataa) => {
    return dataa?.map((item, idx) => {
      return (
        <div
          onClick={() => {
            setId(item.id);
            handleOpen();
          }}
        >
          <ListItem desc={item.desc} name={item.name} icon={item.icon} />
        </div>
      );
    });
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { wid },
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    py: 5,
    px: 5,
  };
  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "hidden" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {<CompanyInfo id={id} />}
      </Modal>
      <Box
        className="w-[80%] sm:w-[60%] md:w-[600px] h-[75vh]"
        sx={style}
      >
        <div style={{ overflow: "scroll", height: "65vh" }}>
          <Column>
            <Row className="justify-between">
              <Column>
                <Text
                  className="not-italic text-gray_900 w-[auto]"
                  variant="h3"
                >
                  {title}
                </Text>
                <Text
                  className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
                  variant="body2"
                >
                  {subTitle}
                </Text>
              </Column>
              <SearchBar
                onChange={(event) =>
                  setSearchQuery(event.target.value.toLowerCase())
                }
              />
            </Row>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2  my-[15px]">
              {currentData}
            </div>
            <Paginationn
              dataPerPage={dataPerPage}
              totaldata={myApi?.length}
              paginate={paginate}
            />
            {/* <BasicPagination /> */}
          </Column>
        </div>
      </Box>
    </>
  );
}
InstitutionList.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  data: PropTypes.array,
};
InstitutionList.defaultProps = {
  title: "Laboratories",
  subTitle: "34 Total Laboratories",
  data: [
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
  ],
};

export default InstitutionList;
