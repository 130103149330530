import { apiSlice } from "../../app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/users/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/users/${credentials.id}/`,
        method: "PATCH",
        body: credentials,
      }),
      invalidatesTags: ["User"],
    }),
    getUser: builder.query({
      query: (id) => "/users/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["User"],
      // transformResponse: responseData => {
      //     return usersAdapter.setAll(initialState, responseData)
      // },
      // providesTags: (result, error, arg) => [
      //     { type: 'User', id: "LIST" },
      //     ...result.ids.map(id => ({ type: 'User', id }))
      // ]
    }),
    getAllUsers: builder.query({
      query: () => "/users/",
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),

    // transformResponse: responseData => {
    //     return usersAdapter.setAll(initialState, responseData)
    // },
    // providesTags: (result, error, arg) => [
    //     { type: 'User', id: "LIST" },
    //     ...result.ids.map(id => ({ type: 'User', id }))
    // ]
  }),
});

export const {
  useCreateUserMutation,
  useGetUserQuery,
  useGetAllUsersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApiSlice;
