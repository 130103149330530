import React, { forwardRef, useState } from "react";
import { Box, LinearProgress, Modal, Typography } from "@mui/material";
import { Column, Row, Text } from "components";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import BasicPagination from "../../../components/Pagination/index";
import EditAccounts from "components/Modals/EditAccount/index.js";
import { faBoxesStacked, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddGlobalItem from "components/Modals/CreateGlobalItem";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import { useGetGlobalItemsQuery } from "features/item/referenceItemApiSlice";

import {
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "features/users/usersApiSlice";
import MaterialTable from "material-table";
import ItemRefList from "./item";
import KitRefList from "./kit";
import Footer from "components/Footer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const ReferenceListPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }
  const dispatch = useDispatch();

  var menu = [
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "View Orders", route_path: () => navigate("/officer/orders") },
        {
          title: "Order History",
          route_path: () => navigate("/officer/orderhistory"),
        },
      ],
      icon: "",
    },
    {
      title: "Items",
      dropdown: true,
      entries: [
        { title: "Add Global Item", route_path: () => handleOpen() },
        {
          title: " Global Inventory",
          route_path: () => navigate("/officer/globalinventory"),
        },
        {
          title: " Reference List",
          route_path: () => navigate("/officer/referenceList"),
        },

        {
          title: "Review Items",
          route_path: () => navigate("/officer/reviewitems"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/officer/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];


  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "scroll" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddGlobalItem />
      </Modal>
      <Header homePath="/officer" menus={menu} />
      <Column className="mx-[auto]  pb-[5rem] w-[90%] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
        <Row className="mb-[3%] content-end grid grid-cols-2">
          <Text variant="h1" className=" not-italic text-gray_900">
            Reference List
          </Text>
        </Row>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label=" auto tabs example"
        >
          <Tab label="Item" {...a11yProps(0)} />
          <Tab label="KIt" {...a11yProps(1)} />
        </Tabs>
        {/* </AppBar> */}
        <TabPanel value={value} index={0}>
          <ItemRefList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <KitRefList />
        </TabPanel>
      </Column>
     <Footer />
    </>
  );
};

export default ReferenceListPage;
