import { Box, InputLabel, MenuItem, Select } from "@mui/material";
import { Input, Column, Text, Button, Radio, RadioGroup } from "components/";
import { Row } from "components/Row";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import { useCreateReferenceItemMutation } from "features/item/referenceItemApiSlice";
import Toast from "components/Toast";
import { IndeterminateCheckBox } from "@material-ui/icons";

function ItemTab() {
  const [errMsg, setErrMsg] = useState("");
  const [addItem, { isLoading }] = useCreateReferenceItemMutation();
  const [successMessage, setSuccessMsg] = useState("");

  const validationSchema = Yup.object().shape({
    itemname: Yup.string().required("Item Name is required"),
    brand: Yup.string().required("Brand is required"),

    description: Yup.string()
      .required("Description is required")
      .max(60, "Description is too long"),
  });
  return (
    <div style={{ overflow: "scroll", height: "50vh" }}>
      <Text className="not-italic text-gray_900 w-[auto]" variant="h2">
        Create Reference Item
      </Text>
      <Formik
        initialValues={{ itemname: "", description: "", brand: "" }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const itemData = await addItem({
              name: values.itemname,
              description: values.description,
              brand: values.brand,
            }).unwrap();
            setErrMsg("");
            setSuccessMsg("Successfully added a global item: ");
            resetForm();
          } catch (err) {
            if (!err) {
              setErrMsg("No Server Response");
            } else if (err.status === 400) {
              setErrMsg("Invalid Credentials");
            } else if (err.status === 401) {
              setErrMsg("Unauthorized");
            } else {
              setErrMsg("Failed to create a global item");
            }
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Column className="gap-2 mt-[10px]">
              {errMsg && <Toast message={errMsg} />}
              {successMessage && (
                <Toast type="success" message={successMessage} />
              )}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Item Name
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="itemname"
                  {...formik.getFieldProps("itemname")}
                ></Input>
              </Row>
              {formik.touched?.itemname && formik.errors?.itemname ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.itemname}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Item Description
                </Text>
                <Input
                  className="diasable w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="description"
                  {...formik.getFieldProps("description")}
                ></Input>
              </Row>
              {formik.touched?.description && formik.errors?.description ? (
                <div>
                  <Text className="not-italic text-red_700_cc " variant="body2">
                    {formik.errors.description}
                  </Text>
                </div>
              ) : null}
              <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                <Text
                  className="not-italic text-gray_900 w-[150px]"
                  variant="h6"
                >
                  Brand
                </Text>
                <Input
                  className="w-[100%]"
                  wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px]  sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                  name="brand"
                  {...formik.getFieldProps("brand")}
                ></Input>
              </Row>
              {formik.touched?.brand && formik.errors?.brand ? (
                <div>
                  <Text
                    className="not-italic text-red_700_cc w-[auto]"
                    variant="body2"
                  >
                    {formik.errors.brand}
                  </Text>
                </div>
              ) : null}
              <Button type="submit" className="w-[200px] mt-4">
                {" "}
                Add Global Item{" "}
              </Button>
            </Column>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ItemTab;
