import { Button, Text } from 'components'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ErrorPage = () => {
    const {state} = useLocation();
    const { errorCode, errorMessage, buttonText, role } = state;
    const navigate = useNavigate();

    function NavigateToHome(role){
        switch (role) {
          case 'admin': navigate('/dashboard'); break;
          case 'client': navigate('/organization'); break;
          case 'supplier': navigate('/supplier'); break;
          case 'soyo': navigate('/officer'); break;
        }
      }

  return (
    <>
    <div className='grid place-content-center mt-[15vh] mx-[5vh]'>
    <Text className='text-center text-green_400 text-[90px]'>{errorCode}</Text>
    <Text className='text-center text-indigo_900 text-[50px]'>{errorMessage}</Text>
    <Button onClick={()=>NavigateToHome(role)} className='mt-[5vh] text-[22px] text-indigo_900'>{buttonText} </Button>
    </div>
    </>
  )
}

export default ErrorPage