import { Box } from "@mui/material";
import { Button } from "components/Button";
import { Column } from "components/Column";
import { Text } from "components/Text";
import {
  useForecastSeenUpdateMutation,
  useGetForecastQuery,
} from "features/dashboard/dashboardApiSlice";
import moment from "moment/moment";
import React, { useState } from "react";
import DatePicker from "react-horizontal-datepicker";
import { BounceLoader } from "react-spinners";
// import DatePicker from "react-horizontal-DatePicker/dist/DatePicker";

const Forecasting = () => {
  const {
    data: result,
    isLoading,
    isSuccess: isSuccessLab,
  } = useGetForecastQuery();
  const [setSeen, { isLoading: isLoadingSeen }] =
    useForecastSeenUpdateMutation();

  const [date, setDate] = React.useState((moment().format("YYYY-MM-DD")));
  // const [seen, setSeen] = useState(false);
  var lookupObject = result?.reduce(function (acc, cur, i) {
    acc[cur.date] = { id: cur.id, forecast: cur.forecast, seen: cur.seen };

    return acc;
  }, {});

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "50px",
          }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <div
          style={{
            borderWidth: 4,
            borderRadius: 10,
            borderStyle: "solid",
            padding: 15,
            borderColor: "#283660",
            // backgroundColor:'rgba(40, 54, 96, 0.2)'
          }}
          className="w-[55vh] sm:w-[70vh] md:w-[90vh] lg:w-[120vh] xl:w-[130vh]"
        >
          <Column>
            <Text className="not-italic text-gray_900 w-[auto]" variant="h4">
              Forecast
            </Text>
            <Text
              className="sm:mt-[2px] md:mt-[2px] lg:mt-[3px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[7px] not-italic text-bluegray_300 w-[auto]"
              variant="body2"
            >
              Future Order Predictions
            </Text>
          </Column>
          
          <DatePicker
            getSelectedDay={(val) => setDate(moment(val).format("YYYY-MM-DD"))}
            // color={"#ffffff"}
            color={"#283660"}
 
          />
          <Text
            variant="h4"
            style={{}}
            className={
              lookupObject[date] != undefined ? (
                lookupObject[date].seen == true ? (
                  "not-italic text-bluegray_300 mx-[auto] font-medium text-center"
                ) : (
                  "not-italic text-gray_900 mx-[auto] font-medium text-center"
                )
              ) : (
                <></>
              )
            }
          >
            {lookupObject[date] == undefined ||
            lookupObject[date] == null ||
            lookupObject[date] == {}
              ? "No forecast available for this day"
              : lookupObject[date].forecast}
            {/* {date} */}
          </Text>
          {/* {true */}
          {/* ??  */}
          {lookupObject[date] != undefined ? (
            lookupObject[date].seen == true ? (
              <Button
                onClick={async () => {
                  try {
                    const institutionData = await setSeen({
                      seen: false,
                      id: lookupObject[date].id,
                    }).unwrap();
                  } catch (err) {
                  }
                }}
              >
                Mark as Unread
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  try {
                    const institutionData = await setSeen({
                      seen: true,
                      id: lookupObject[date].id,
                    }).unwrap();
                  } catch (err) {
                  }
                }}
              >
                Mark as Read
              </Button>
            )
          ) : (
            <></>
          )}
          {/* } */}
        </div>
      )}
    </>
  );
};
export default Forecasting;
