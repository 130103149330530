import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";

import "@reach/combobox/styles.css";

import { useEffect } from "react";

export default function Search({
  placeholder,
  setLocation,
  initalLocation,
  disabled = false,
}) {
  //const map = useGoogleMap();
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    //debounce: 500,
    // requestOptions: {
    //   location: { lat: -74, lng: 40.7 },
    //   radius: 100 * 1000,
    // },
  });
  useEffect(() => {
    if (initalLocation) setValue(initalLocation, false);
  }, []);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();
    const results = await getGeocode({ address });

    const { lat, lng } = getLatLng(results[0]);
    //map.panTo({ lat, lng });
    setLocation({ lat, lng }, address);
  };

  return (
    <div className="places-container">
      <Combobox onSelect={handleSelect} style={{ zIndex: 9999999999 }}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          // disabled={!ready || disabled}
          className="combobox-input px-3 bg-gray_50 mb-[15px] rounded-radius845 border border-gray_50 border-solid 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flexlg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%]  xl:h-[41px] xl:mt-[8px]"
          placeholder={placeholder}
        />
        <ComboboxPopover
          style={{ zIndex: 9999999999, borderWidth: 0, boxShadow: 4 }}
        >
          <ComboboxList>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <ComboboxOption
                  className="z-index-1"
                  key={place_id}
                  value={description}
                />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
