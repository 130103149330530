import { apiSlice } from "../../app/api/apiSlice";

export const ReferenceKitApiSlice = apiSlice.injectEndpoints({
    endpoints:     builder => ({
        createReferenceKit: builder.mutation({
            query: credentials => ({
                // mode: 'cors',
                url: '/inventory/global/kits/',
                method: 'POST',
                body:   credentials
            }),
            invalidatesTags: ["GlobalKit"],

        }),
        getReferenceKits: builder.query({
            query: () => 'inventory/global/kits/',
            keepUnusedDataFor: 5,
            providesTags: ["GlobalKit"],
        }),
        getReferenceKit: builder.query({
            query: (id) => '/inventory/global/kits/'+ id+'/',
            keepUnusedDataFor: 5,
            providesTags: ["GlobalKit"],

        })
    }),
   
})

export const {
    useCreateReferenceKitMutation,
    useGetReferenceKitQuery,
    useGetReferenceKitsQuery,
    
} = ReferenceKitApiSlice 