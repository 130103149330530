import { Box } from "@mui/material";
import { Column } from "components/Column";
import { Text } from "components/Text";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Card from "components/Card";
import { Row } from "components/Row";
import {
  faBoxesStacked,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardForProforma from "components/Card/CardForProforma";
import {
  useAcceptProformaMutation,
  useGetOrderClientQuery,
} from "features/order/ordersApiSlice";
import Toast from "components/Toast";
import { BounceLoader } from "react-spinners";
function Proforma({ id }) {
  const [acceptProforma, { isLoadingAccept }] = useAcceptProformaMutation();
  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    pl: 7,
    py: 5,
  };
  const { data, isLoading, isSuccess, isError, error } =
    useGetOrderClientQuery(id);
  var checkBool;
  if (data?.status == "placed" || data?.status == "accepted") {
    checkBool = true;
  } else checkBool = false;
  var routeUrl = "https://test.api.soyo.testserver.awuraplc.org";
  //var routeUrl = "https://api.soyo.soyoafricabiotech.com";
  return (
    <Box className="w-[80%] md:w-[600px] h-[75vh]" sx={style}>
      <div style={{ overflow: "scroll", height: "65vh" }}>
        <Column className="gap-3 w-[100%] xl:w-[95%] h-[80vh]">
          {errMsg && <Toast message={errMsg} />}
          {successMessage && <Toast type="success" message={successMessage} />}
          <Row className="mb-[3%] content-end grid grid-cols-2">
            <Text variant="h1" className=" not-italic text-gray_900">
              Proformas
            </Text>
          </Row>
          {/* <div onClick={() => }> */}
          {isLoading ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                height: "50px",
              }}
            >
              <BounceLoader
                color="#283660"
                // className="bg-green_400"
              />
            </Box>
          ) : (
            <>
              <CardForProforma
                row11={"Proforma " + data?.offers?.proforma_1?.id}
                row12={
                  data?.offers?.proforma_1?.no_products +
                  " items , " +
                  data?.offers?.proforma_1?.total_price +
                  "ETB"
                }
                button1action={() => {
                  const url = window.URL.createObjectURL(
                    new Blob([data?.offers?.proforma_1?.file])
                  );
                  const link = document.createElement("a");
                  // link.href = routeUrl + p1;
                  link.href = routeUrl + data?.offers?.proforma_1?.file;
                  link.target = "_blank";
                  const fileName =
                    "Proforma " + data?.offers?.proforma_1?.id + ".pdf";
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                }}
                // button1name="Download"
                button2name={checkBool ? "Accept" : ""}
                button2Icon={
                  data?.offers?.proforma_1?.client_acept == true
                    ? faCheckCircle
                    : ""
                }
                button2color={
                  data?.offers?.proforma_1?.client_acept == true
                    ? "FillGreen400"
                    : ""
                }
                // button2color="#283660"
                button1color={"#ffffff"}
                button2action={async () => {
                  try {
                    if (data?.offers?.proforma_2?.client_acept == true) {
                      const itemData1 = await acceptProforma({
                        id: data?.offers?.proforma_2?.id,
                        client_accept: false,
                      }).unwrap();
                    }

                    if (data?.offers?.proforma_3?.client_acept == true) {
                      const itemData2 = await acceptProforma({
                        id: data?.offers?.proforma_3?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    if (data?.offers?.proforma_4?.client_acept == true) {
                      const itemData3 = await acceptProforma({
                        id: data?.offers?.proforma_4?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    const itemData4 = await acceptProforma({
                      id: data?.offers?.proforma_1?.id,
                      client_accept: true,
                    }).unwrap();
                    setErrMsg("");
                    // setSuccessMsg(
                    //   "Proforma " + data?.offers?.proforma_1?.id + " accepted"
                    // );
                  } catch (err) {
                    if (!err) {
                      setErrMsg("No Server Response");
                    } else if (err.status === 400) {
                      setErrMsg(
                        "You have already accepted a proforma. Go back to offers page and come back to see your choice."
                      );
                    } else if (err.status === 401) {
                      setErrMsg("Unauthorized");
                    } else {
                      setErrMsg("Failed to accept Proforma");
                    }
                  }
                }}
              />
              {/* </div> */}
              <CardForProforma
                row11={"Proforma " + data?.offers?.proforma_2?.id}
                row12={
                  data?.offers?.proforma_2?.no_products +
                  " items , " +
                  data?.offers?.proforma_2?.total_price +
                  "ETB"
                }
                button1action={() => {
                  const url = window.URL.createObjectURL(
                    new Blob([data?.offers?.proforma_2?.file])
                  );
                  const link = document.createElement("a");
                  // link.href = routeUrl + p2;
                  link.href = routeUrl + data?.offers?.proforma_2?.file;
                  const fileName =
                    "Proforma " + data?.offers?.proforma_2?.id + ".pdf";

                  link.target = "_blank";
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                }}
                button2name={checkBool ? "Accept" : ""}
                button2Icon={
                  data?.offers?.proforma_2?.client_acept == true
                    ? faCheckCircle
                    : ""
                }
                button2color={
                  data?.offers?.proforma_2?.client_acept == true
                    ? "FillGreen400"
                    : ""
                }
                // button1name="Download"
                // button2name="Accept"
                button1color={"#ffffff"}
                button2action={async () => {
                  try {
                    if (data?.offers?.proforma_4?.client_acept == true) {
                      const itemData1 = await acceptProforma({
                        id: data?.offers?.proforma_4?.id,
                        client_accept: false,
                      }).unwrap();
                    }

                    if (data?.offers?.proforma_3?.client_acept == true) {
                      const itemData2 = await acceptProforma({
                        id: data?.offers?.proforma_3?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    if (data?.offers?.proforma_1?.client_acept == true) {
                      const itemData3 = await acceptProforma({
                        id: data?.offers?.proforma_1?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    const itemData4 = await acceptProforma({
                      id: data?.offers?.proforma_2?.id,
                      client_accept: true,
                    }).unwrap();

                    setErrMsg("");
                    // setSuccessMsg(
                    //   "Proforma " + data?.offers?.proforma_2?.id + " accepted"
                    // );
                  } catch (err) {
                    if (!err) {
                      setErrMsg("No Server Response");
                    } else if (err.status === 400) {
                      setErrMsg("Invalid Credentials");
                    } else if (err.status === 401) {
                      setErrMsg("Unauthorized");
                    } else {
                      setErrMsg("Failed to accept Proforma");
                    }
                  }
                }}
              />
              <CardForProforma
                row11={"Proforma " + data?.offers?.proforma_3?.id}
                row12={
                  data?.offers?.proforma_3?.no_products +
                  " items , " +
                  data?.offers?.proforma_3?.total_price +
                  "ETB"
                }
                button1action={() => {
                  const url = window.URL.createObjectURL(
                    new Blob([data?.offers?.proforma_3?.file])
                  );
                  const link = document.createElement("a");
                  // link.href = routeUrl + p3;
                  link.href = routeUrl + data?.offers?.proforma_3?.file;
                  const fileName =
                    "Proforma " + data?.offers?.proforma_3?.id + ".pdf";

                  link.target = "_blank";
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                }}
                // button1name="Download"
                // button2name="Accept"
                button2name={checkBool ? "Accept" : ""}
                button2Icon={
                  data?.offers?.proforma_3?.client_acept == true
                    ? faCheckCircle
                    : ""
                }
                button2color={
                  data?.offers?.proforma_3?.client_acept == true
                    ? "FillGreen400"
                    : ""
                }
                button1color={"#ffffff"}
                button2action={async () => {
                  try {
                    if (data?.offers?.proforma_2?.client_acept == true) {
                      const itemData1 = await acceptProforma({
                        id: data?.offers?.proforma_2?.id,
                        client_accept: false,
                      }).unwrap();
                    }

                    if (data?.offers?.proforma_4?.client_acept == true) {
                      const itemData2 = await acceptProforma({
                        id: data?.offers?.proforma_4?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    if (data?.offers?.proforma_1?.client_acept == true) {
                      const itemData3 = await acceptProforma({
                        id: data?.offers?.proforma_1?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    const itemData4 = await acceptProforma({
                      id: data?.offers?.proforma_3?.id,
                      client_accept: true,
                    }).unwrap();

                    setErrMsg("");
                    // setSuccessMsg(
                    //   "Proforma " + data?.offers?.proforma_3?.id + " accepted"
                    // );
                  } catch (err) {
                    if (!err) {
                      setErrMsg("No Server Response");
                    } else if (err.status === 400) {
                      setErrMsg("Invalid Credentials");
                    } else if (err.status === 401) {
                      setErrMsg("Unauthorized");
                    } else {
                      setErrMsg("Failed to accept Proforma");
                    }
                  }
                }}
              />
              <CardForProforma
                row11={"Proforma " + data?.offers?.proforma_4?.id}
                row12={
                  data?.offers?.proforma_4?.no_products +
                  " items , " +
                  data?.offers?.proforma_4?.total_price +
                  "ETB"
                }
                button1action={() => {
                  const url = window.URL.createObjectURL(
                    new Blob([data?.offers?.proforma_4?.file])
                  );
                  const link = document.createElement("a");
                  // link.href = routeUrl + p4;
                  link.href = routeUrl + data?.offers?.proforma_4?.file;
                  const fileName =
                    "Proforma " + data?.offers?.proforma_4?.id + ".pdf";

                  link.target = "_blank";
                  link.setAttribute("download", fileName);
                  document.body.appendChild(link);
                  link.click();
                }}
                // button1name="Download"
                // button2name="Accept"
                button2name={checkBool ? "Accept" : ""}
                button2Icon={
                  data?.offers?.proforma_4?.client_acept == true
                    ? faCheckCircle
                    : ""
                }
                button2color={
                  data?.offers?.proforma_4?.client_acept == true
                    ? "FillGreen400"
                    : ""
                }
                button1color="#ffffff"
                button2action={async () => {
                  try {
                    if (data?.offers?.proforma_2?.client_acept == true) {
                      const itemData1 = await acceptProforma({
                        id: data?.offers?.proforma_2?.id,
                        client_accept: false,
                      }).unwrap();
                    }

                    if (data?.offers?.proforma_3?.client_acept == true) {
                      const itemData2 = await acceptProforma({
                        id: data?.offers?.proforma_3?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    if (data?.offers?.proforma_1?.client_acept == true) {
                      const itemData3 = await acceptProforma({
                        id: data?.offers?.proforma_1?.id,
                        client_accept: false,
                      }).unwrap();
                    }
                    const itemData4 = await acceptProforma({
                      id: data?.offers?.proforma_4?.id,
                      client_accept: true,
                    }).unwrap();
                    setErrMsg("");
                    // setSuccessMsg(
                    //   "Proforma " + data?.offers?.proforma_4?.id + " accepted"
                    // );
                  } catch (err) {
                    if (!err) {
                      setErrMsg("No Server Response");
                    } else if (err.status === 400) {
                      setErrMsg("Invalid Credentials");
                    } else if (err.status === 401) {
                      setErrMsg("Unauthorized");
                    } else {
                      setErrMsg("Failed to accept Proforma");
                    }
                  }
                }}

                // button2color="#283660"
              />
            </>
          )}
        </Column>
      </div>
    </Box>
  );
}

Proforma.propTypes = {
  icon: PropTypes.object,
  data: PropTypes.array,
  title: PropTypes.string,
  desc: PropTypes.string,
  wid: PropTypes.number,
};
Proforma.defaultProps = {
  data: [
    { company: "Thermo", desc: 500 },
    { company: "NEB", desc: 500 },
    { company: "Thermo", desc: 500 },
    { company: "NEB", desc: 500 },
  ],
  title: "International Clinical Laboratories",
  desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  icon: faBoxesStacked,
};

export default Proforma;
