import { Box, Typography } from "@mui/material";
import ReactDOM from "react-dom";
import React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import AppBar from "@mui/material/AppBar/";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Text } from "components/Text";
import { Row } from "components/Row";
import { Input } from "components/Input";
import { Formik, Form } from "formik";
import { Column } from "components/Column";
import { Button } from "components/Button";
import colors from "tailwindcss/colors";
import UserTabComponent from "./user";
import CompanyTabComponent from "./company";
import ClientTabComponent from "./client";
import SupplierTabComponent from "./supplier";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//     width: "100%",
//     backgroundColor: '#ffffff'
//   }
// }));

function CreateAccount() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    px: 3,
    py: 5,
  };

  //   const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <Box className="w-[60%] md:w-[600px] h-[75vh]" sx={style}>
      <Text className="not-italic text-gray_900 w-[auto] ml-[3%]" variant="h2">
        New Institution Account
      </Text>
      <div>
        {/* <AppBar position="static" color="default"> */}
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label=" auto tabs example"
        >
          {/* <Tab label="User" {...a11yProps(0)} /> */}
          {/* <Tab label="Institution" {...a11yProps(1)} /> */}
          <Tab label="Client" {...a11yProps(0)} />
          <Tab label="Supplier" {...a11yProps(1)} />
        </Tabs>
        {/* </AppBar> */}
        {/* <TabPanel value={value} index={0}>
            <UserTabComponent />
          </TabPanel> */}
        {/* <TabPanel value={value} index={1}>
        <CompanyTabComponent />
      </TabPanel> */}
        <TabPanel value={value} index={0}>
          <ClientTabComponent />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SupplierTabComponent />
        </TabPanel>
      </div>
    </Box>
  );
}

export default CreateAccount;
