import React from "react";
import PropTypes from "prop-types";

import { Column, Row, Img, Text, Button } from "components";
import { useNavigate } from "react-router-dom";
import { Sell } from "@mui/icons-material";

const RevenueElement = ({ icon, title, amount, desc, ...restProps }) => {
  const navigate = useNavigate();



  return (
    <>
      <div
        style={{ borderRadius: 10, backgroundColor: "rgba(40, 54, 96, 0.2)" }}
        className="p-2 mt-1 grid grid-cols-1 justify-items-center inline-block  h-[140px] md:h-[130px] lg:h-[140px] xl:h-[140px] 2xl:h-[150px] 3xl:h-[160px] w-[140px] md:w-[155px] lg:w-[160px] xl:w-[170px] 2xl:w-[175px] 3xl:w-[180px]"
      >
        <Text className="not-italic text-gray_900" variant="h5">
          {title}
        </Text>

        {icon}
        <Text className='text-center' variant="h6">{amount}</Text>

        <Text className='text-center' variant="body5">{desc}</Text>
      </div>
    </>
  );
};
RevenueElement.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  amount: PropTypes.string,
  desc: PropTypes.string,
};
RevenueElement.defaultProps = {
  icon: (
    <Sell htmlColor="#ffffff" className="my-3" fontSize="large" alt="user" />
  ),
  title: "Total Sales",
  amount: "450,8977ETB",
  desc: "161 Transactions",
};
export default RevenueElement;
