import { Text } from "components/Text";
import { useGetInstitutionQuery } from "features/institution/institutionApiSlice";
import React from "react";

const InstitutionLookUp = ({id,timeline=false}) => {
  const { data } = useGetInstitutionQuery(id);
  if(timeline==true){
   return <Text className="mt-[3px] not-italic text-bluegray_300 w-[auto]"
    variant="body2">{data?.name}</Text>
  }else  return <div>{data?.name}</div>;

};

export default InstitutionLookUp;
