import { apiSlice } from "../../app/api/apiSlice";

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addToCart: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/cart/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Cart"],
    }),
    createOrder: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/orders/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["Cart"],
    }),
    getOrdersClient: builder.query({
      query: () => "/orders/",
      keepUnusedDataFor: 5,
      providesTags: ["Cart"],
    }),
    getOrderClient: builder.query({
      query: (id) => "/orders/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["Cart"],
    }),
    getOffersClient: builder.query({
      query: () => "offers/",
      keepUnusedDataFor: 5,
      providesTags: ["Cart"],
    }),

    // getCartItems: builder.query({
    //     query: () => 'orders/',
    //     keepUnusedDataFor: 5,
    //     providesTags: ["Cart"],

    // }),
    acceptProforma: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/offers/${credentials.id}/`,
        method: "PATCH",
        body: {
          client_accept: credentials.client_accept,
        },
      }),
      invalidatesTags: ["Cart"],
    }),
    updateOrderStatus: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/orders/${credentials.id}/`,
        method: "PATCH",
        body: {
          status: credentials.status,
        },
      }),
      invalidatesTags: ["Cart"],
    }),
    updateCartQuantity: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/cart/${credentials.id}/`,
        method: "PATCH",
        body: {
          quantity: credentials.quantity,
        },
      }),
      invalidatesTags: ["Cart"],
    }),
    getCartItems: builder.query({
      query: () => "cart/",
      keepUnusedDataFor: 5,
      providesTags: ["Cart"],
    }),
    deleteCartItem: builder.mutation({
      query: (id) => ({
        // mode: 'cors',
        url: `/cart/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),

    //     getGlobalItemClient: builder.query({
    //         query: (id) => '/inventory/global/current/'+id+'/',
    //         keepUnusedDataFor: 5,
    //         providesTags: ["GlobalItem"],

    //     })
  }),
});

export const {
  useAddToCartMutation,
  useGetOrdersClientQuery,
  useGetCartItemsQuery,
  useDeleteCartItemMutation,
  useCreateOrderMutation,
  useAcceptProformaMutation,
  useUpdateCartQuantityMutation,
  useGetOffersClientQuery,
  useGetOrderClientQuery,
  useUpdateOrderStatusMutation,
} = ordersApiSlice;
