import { Text } from "components/Text";
import { useGetInstitutionQuery } from "features/institution/institutionApiSlice";
import {
  useGetProductQuery,
  useGetReferenceItemQuery,
} from "features/item/referenceItemApiSlice";
import { useGetReferenceKitQuery } from "features/item/referenceKitApiSlice";
import React from "react";

const ProductsLookUp = (id) => {
  const { data } = useGetProductQuery(id.id);
  //   const { data: data2 } = useGetReferenceKitQuery(id.id);
  return (
    <Text className="not-italic text-[20px] text-gray_900" variant="h6">
      {data?.name}
    </Text>
  );
};

export default ProductsLookUp;
