import React from "react";
import { Row } from "components";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import TopList from "components/TopList";
import Timelines from "components/Timeline";
import RevenueList from "components/RevenueList";
import StatBoxes from "components/StatBoxes";
import Graph from "components/Graph";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ProductList from "components/Modals/ProductList";
import TimelineModal from "components/Modals/Timeline";
import SalesReport from "components/Modals/Sales";
import ProductCategory from "components/Modals/ProductCategory";
import CompanyInfo from "components/Modals/CompanyInfo";
import InstitutionList from "components/Modals/InstitutionList";
import { Box, Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import CreateGlobalItem from "components/Modals/CreateGlobalItem";
import Footer from "components/Footer";
import {
  useGetTopLaboratoriesQuery,
  useGetTopOrganizationsQuery,
  useGetTopSuppliersQuery,
} from "features/dashboard/dashboardApiSlice";
import ClientInfo from "components/Modals/CompanyInfo/ClientInfo";
import LabInfo from "components/Modals/CompanyInfo/lab";
import { BounceLoader } from "react-spinners";
import Forecasting from "components/Forecasting";

const OfficerHome = () => {
  const [open, setOpen] = React.useState(false);
  const [x, setX] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useGetTopSuppliersQuery();
  const editable = structuredClone(data);
  const {
    data: dataOrg,
    isLoading: isLoadingOrg,
    isSuccess: isSuccessOrg,
  } = useGetTopOrganizationsQuery();
  const editableOrg = structuredClone(dataOrg);
  const {
    data: dataLab,
    isLoading: isLoadingLab,
    isSuccess: isSuccessLab,
  } = useGetTopLaboratoriesQuery();
  const editableLab = structuredClone(dataLab);
  var listSuppliers = [],
    listOrganizations = [],
    listLaboratories = [],
    listSupplierModal = [],
    listOrgModal = [],
    listLabModal = [];
  if (isSuccess) {
    for (let index = 0; index < editable?.length; index++) {
      if (index < 4) {
        listSupplierModal.push({
          desc: editable[index]?.name,
          name: editable[index]?.products_sold + " sold",
          icon: "building",
          id: editable[index]?.id,
        });
        listSuppliers.push({
          company: editable[index]?.name,
          desc: editable[index]?.products_sold + " sold",
          clicked: () => {
            chooseModal(8, editable[index].id);
            handleOpen();
          },
        });
      } else {
        listSupplierModal.push({
          desc: editable[index]?.name,
          name: editable[index]?.products_sold + " sold",
          id: editable[index]?.id,
          icon: "building",
        });
      }
    }
  }
  if (isSuccessOrg) {
    for (let index = 0; index < editableOrg?.length; index++) {
      if (index < 4) {
        listOrgModal.push({
          desc: editableOrg[index]?.name,
          name: editableOrg[index]?.products_bought + " bought",
          id: editableOrg[index]?.id,
          icon: "building",
        });
        listOrganizations.push({
          company: editableOrg[index]?.name,
          desc: editableOrg[index]?.products_bought + " bought",
          clicked: () => {
            chooseModal(14, editableOrg[index].id);
            handleOpen();
          },
        });
      } else {
        listOrgModal.push({
          desc: editableOrg[index]?.name,
          name: editableOrg[index]?.products_bought + " bought",
          icon: "building",
          id: editableOrg[index]?.id,
        });
      }
    }
  }
  if (isSuccessLab) {
    for (let index = 0; index < editableLab?.length; index++) {
      if (index < 4) {
        listLabModal.push({
          desc: editableLab[index]?.name,
          name: editableLab[index]?.products_bought + " bought",
          icon: "lab",
          id: editableLab[index]?.id,
        });
        listLaboratories.push({
          company: editableLab[index]?.name,
          desc: editableLab[index]?.products_bought + " bought",
          clicked: () => {
            chooseModal(15, editableLab[index].id);
            handleOpen();
          },
        });
      } else {
        listLabModal.push({
          desc: editableLab[index]?.name,
          name: editableLab[index]?.products_bought + " bought",
          icon: "lab",
          id: editableLab[index]?.id,
        });
      }
    }
  }
  var statEntries = [
    {
      title: "Suppliers",
      number: "14",
      onClick: () => {
        chooseModal(10);
        handleOpen();
      },
    },
    {
      title: "Global Inventory",
      number: "14679",
      onClick: () => {
        chooseModal(9);
        handleOpen();
      },
    },
    {
      title: "Laboratories",
      number: "16",
      onClick: () => {
        chooseModal(12);
        handleOpen();
      },
    },
    {
      title: "Organizations",
      number: "12",
      onClick: () => {
        chooseModal(11);
        handleOpen();
      },
    },
  ];
  var menu = [
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "View Orders", route_path: () => navigate("/officer/orders") },
        {
          title: "Order History",
          route_path: () => navigate("/officer/orderhistory"),
        },
      ],
      icon: "",
    },
    {
      title: "Items",
      dropdown: true,
      entries: [
        {
          title: "Add Global Item",
          route_path: () => {
            chooseModal(13);
            handleOpen();
          },
        },
        {
          title: " Global Inventory",
          route_path: () => navigate("/officer/globalinventory"),
        },
        {
          title: " Reference List",
          route_path: () => navigate("/officer/referenceList"),
        },
        {
          title: "Review Items",
          route_path: () => navigate("/officer/reviewitems"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/officer/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];

  function chooseModal(idx, id) {
    switch (idx) {
      case 1:
        setX(<ProductList />);
        break;
      case 2:
        setX(<ProductList />);
        break;
      case 3:
        setX(<ProductList />);
        break;
      case 4:
        setX(<TimelineModal />);
        break;
      case 5:
        setX(<SalesReport />);
        break;
      case 6:
        setX(<ProductList />);
        break;
      case 7:
        setX(<ProductCategory />);
        break;
      case 8:
        setX(<CompanyInfo id={id} />);
        break;
     
      case 10:
        setX(
          <InstitutionList
            title="Total Suppliers"
            subTitle={editable?.length + " Total Suppliers"}
            data={listSupplierModal}
          />
        );
        break;
      case 11:
        setX(
          <InstitutionList
            title="Total Organizations"
            subTitle={editableOrg?.length + " Total Organizations"}
            data={listOrgModal}
          />
        );
        break;
      case 12:
        setX(
          <InstitutionList
            title="Total Laboratories"
            subTitle={editableLab?.length + " Total Laboratories"}
            data={listLabModal}
          />
        );
        break;
      case 13:
        setX(<CreateGlobalItem />);
        break;
      case 14:
        setX(<ClientInfo id={id} />);
        break;
      case 15:
        setX(<LabInfo id={id} />);
        break;
      default:
        setX(<CompanyInfo />);
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <CompanyInfo />}
      </Modal>
      <Header homePath="/officer" menus={menu} />
      {isLoading || isLoadingOrg ||isLoadingLab ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "100vh",
          }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <>
      <Row class="mx-[auto] w-[75%] sm:w-[80%] mt-[20px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <TopList
          icon="building"
          entries={listSuppliers}
          title="Top 4 Suppliers"
          subtitle={editable?.length + " Total Suppliers"}
          seeMore={() => {
            chooseModal(10);
            handleOpen();
          }}
        />
        <TopList
          icon="lab"
          entries={listLaboratories}
          title="Top 4 Laboratories"
          subtitle={editableLab?.length + " Total Laboatories"}
          seeMore={() => {
            chooseModal(12);
            handleOpen();
          }}
        />
        <TopList
          icon="building"
          entries={listOrganizations}
          title="Top 4 Organizations"
          subtitle={editableOrg?.length + " Total Organizations"}
          seeMore={() => {
            chooseModal(11);
            handleOpen();
          }}
        />
        {/* </Row>
    <Row class='mx-[auto] w-[88%] sm:w-[85%] sm:w-[85%] md:w-[87%] lg:w-[85%] xl:w-[85%] mt-[20px] md:mt-[30px] lg:mt-[40px] xl:mt-[50px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-3 sm:gap-0'> */}
        <div class="sm:ml-0 ">
        <RevenueList title={'Sales Summary'} seeMore={()=>{chooseModal(5);handleOpen();}}  /> 

        </div>

        <div>
          <Timelines
            seeMore={() => {
              chooseModal(4);
              handleOpen();
            }}
          />
        </div>
        <div class="md:mt-[40px] sm:my-[auto] md:mt-[50px] lg:mt-[50px] xl:mt-[70px]">
          <StatBoxes entries={statEntries} />
        </div>
      </Row>
      <div class='w-[75%] sm:w-[80%] mx-[auto] md:w-[80%] lg:w-[80%] xl:w-[80%] mt-[20px] md:mt-[20px] lg:mt-[20px] xl:mt-[20px] pr-[20%]'>
    <Forecasting />
    
      </div>
      <div class="w-[75%] pb-[5rem] sm:w-[80%] mx-[auto] md:w-[80%] lg:w-[80%] xl:w-[80%] mt-[20px] md:mt-[20px] lg:mt-[20px] xl:mt-[20px]">
        <Graph role={"soyo"} />
      </div>
      </>)}
      <Footer />
    </>
  );
};

export default OfficerHome;
