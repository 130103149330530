import { Box, LinearProgress } from "@mui/material";
import { useGetGlobalItemsQuery } from "features/item/referenceItemApiSlice";
import MaterialTable from "material-table";
import React, { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useGetReferenceKitsQuery } from "features/item/referenceKitApiSlice";
import { Button, Text } from "components";
import KitItems from "components/KitItems";
import KitList from "components/KitItems/kits";
import { BounceLoader } from "react-spinners";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const KitRefList = () => {
  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetReferenceKitsQuery();

  const editable = structuredClone(records);
  const selectedRow = React.useRef([]);
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };
  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Name",
      field: "name",
      align: "left",
      validate: (rowData) => {
        if (rowData.name === undefined || rowData.name == "") {
          return "Required";
        } else return true;
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Description",
      field: "description",
      align: "left",
      onClick: (rowData) => {
      },
      filterPlaceholder: "Filter",
      validate: (rowData) => {
        if (rowData.description === undefined || rowData.description == "") {
          return "Required";
        } else return true;
      },
    },
  ];
  return (
    <>
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <MaterialTable
          editable={
            {
              // onRowUpdate: (newRow, oldRow) =>
              //   new Promise(async (resolve, reject) => {
              //     try {
              //       const userData = await updateUser({
              //         "id":oldRow.id,
              //         "username": oldRow.username,
              //         "first_name": newRow.firstName,
              //         "last_name": newRow.lastName,
              //         "email": newRow.email,
              //         "phone": newRow.phone,
              //         "role": newRow.role,
              //         "institution": newRow.institution,
              //         "position": newRow.position
              //       }).unwrap();
              //       console.log(userData);
              //       setErrMsg("");
              //       setSuccessMsg("Successfully edited user");
              //     } catch (err) {
              //       console.log(err.data);
              //       if (!err) {
              //         setErrMsg("No Server Response");
              //       } else if (err.status === 400) {
              //         setErrMsg("Invalid Credentials");
              //       } else if (err.status === 401) {
              //         setErrMsg("Unauthorized");
              //       } else {
              //         setErrMsg("Failed to create a global item");
              //       }
              //     }
              //     setTimeout(() => resolve(), 500);
              //   }),
              // onRowDelete: (Row) =>
              //   new Promise(async (resolve, reject) => {
              //     try {
              //       const userData = await deleteUser(Row.id).unwrap();
              //       console.log(userData);
              //       setErrMsg("");
              //       setSuccessMsg("Successfully deleted user");
              //     } catch (err) {
              //       console.log(err.data);
              //       if (!err) {
              //         setErrMsg("No Server Response");
              //       } else if (err.status === 400) {
              //         setErrMsg("Invalid Credentials");
              //       } else if (err.status === 401) {
              //         setErrMsg("Unauthorized");
              //       } else {
              //         setErrMsg("Failed to create a global item");
              //       }
              //     }
              //     setTimeout(() => resolve(), 500);
              //   }),
            }
          }
          options={{
            showTitle: false,
            sorting: true,
            emptyRowsWhenPaging: false,
            search: true,
            filtering: true,
            pageSizeOptions: [5, 10, 15],
            exportButton: true,
            exportAllData: true,
            // selection: true,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,

            selectionProps: (rowData) => ({ color: "primary" }),
            headerSelectionProps: (header) => ({ color: "primary" }),
            searchFieldAlignment: "left",
            grouping: true,
            columnsButton: true,
            rowStyle: {
              background: "rgba(40, 54, 96, 0.2)",
              color: "#181c32",
            },

            headerStyle: {
              background: "rgba(40, 54, 96, 0.2)",
              color: "#181c32",
              fontWeight: "bolder",
            },
            filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
            detailPanelColumnStyle: { background: "rgba(40, 54, 96, 0.2)" },
            actionsColumnIndex: 10,
          }}
          onSelectionChange={(e) => {
            handleClick(e);
          }}
          detailPanel={
            (rowData) => {
              return (
                <div
                  style={{
                    background: "rgba(40, 54, 96, 0.2)",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                  className="w-[100%] grid grid-cols-4"
                >
                  {" "}
                  {rowData.items?.map((item, idx) => {
                    return <KitItems id={item} />;
                  })}
                  {/* <KitList data={rowData.items} />; */}
                </div>
              );
            }
            // )
          }
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          columns={columns}
          data={editable}
          icons={tableIcons}
        />
      )}
    </>
  );
};

export default KitRefList;
