import { apiSlice } from "../../app/api/apiSlice";

export const dashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // createInstitution: builder.mutation({
    //     query: credentials => ({
    //         // mode: 'cors',
    //         url: '/institutions/',
    //         method: 'POST',
    //         body:   credentials
    //     }),
    //     invalidatesTags: ["Institution"],

    // }),
    // createClient: builder.mutation({
    //     query: credentials => ({
    //         // mode: 'cors',
    //         url: '/laboratories/',
    //         method: 'POST',
    //         body:   credentials
    //     }),
    //     invalidatesTags: ["Institution"],

    // }),
    // createSupplier: builder.mutation({
    //     query: credentials => ({
    //         // mode: 'cors',
    //         url: '/suppliers/',
    //         method: 'POST',
    //         body:   credentials
    //     }),
    //     invalidatesTags: ["Institution"],

    // }),
    // updateInstitution: builder.mutation({
    //     query: credentials => ({
    //         // mode: 'cors',
    //         url: `/institutions/${credentials.id}/`,
    //         method: 'PUT',
    //         body: credentials,
    //     }),
    //     invalidatesTags: ["Institution"],

    // }),
    // deleteInstitution: builder.mutation({
    //     query: id => ({
    //         url: `/institutions/${id}/`,
    //         method: 'DELETE',
    //     }),
    //     invalidatesTags: ["Institution"],

    // }),
    getDashboard: builder.query({
      query: () => "/institutions/stats/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getOrderStats: builder.query({
      query: () => "/orders/stats/",
      keepUnusedDataFor: 5,
      providesTags: ["Order"],
    }),
    getUserStats: builder.query({
      query: () => "/users/stats/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getTopSuppliers: builder.query({
      query: () => "/suppliers/top/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getTopSupplier: builder.query({
      query: (id) => "/suppliers/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getTopOrganizations: builder.query({
      query: () => "/organizations/top/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getTopOrganization: builder.query({
      query: (id) => "/clients/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getTopLaboratories: builder.query({
      query: () => "/laboratories/top/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
    getForecast: builder.query({
      query: () => "/forecast/",
      keepUnusedDataFor: 5,
      providesTags: ["Forecast"],
    }),
    ForecastSeenUpdate: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: `/forecast/${credentials.id}/`,
        method: "PATCH",
        body: {
          seen: credentials.seen,
        },
      }),
      invalidatesTags: ["Forecast"],
    }),
    getTopLaboratory: builder.query({
      query: (id) => "/clients/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["Institution"],
    }),
  }),
});

export const {
  useGetDashboardQuery,
  useGetTopSupplierQuery,
  useGetTopSuppliersQuery,
  useGetTopLaboratoriesQuery,
  useGetTopLaboratoryQuery,
  useGetTopOrganizationQuery,
  useGetOrderStatsQuery,
  useGetTopOrganizationsQuery,
  useGetForecastQuery,
  useForecastSeenUpdateMutation,
  useGetUserStatsQuery,
} = dashboardApiSlice;
