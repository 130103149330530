import { Column } from "components/Column";
import { Text } from "components/Text";
import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  useGetOrderStatsQuery,
  useGetUserStatsQuery,
} from "features/dashboard/dashboardApiSlice";
import { selectCurrentRole } from "features/auth/authSlice";
import { useSelector } from "react-redux";
var hei, wid;
if (window.innerWidth < 768) {
} else if (window.innerWidth < 1024) {
} else if (window.innerWidth < 1281) {
  hei = 200;
  wid = 600;
} else if (window.innerWidth < 1441) {
  hei = 400;
  wid = 900;
} else if (window.innerWidth < 1729) {
}

export default function GraphSales({ width, height }) {
  const { data: orders, isLoading, isSuccess } = useGetOrderStatsQuery();
  const editable = structuredClone(orders);
  const data = [];
  if (isSuccess) {
    editable?.history.map((element) => {
      if (
        element?.incomplete_orders > 0 ||
        element?.completed_transactions > 0
      ) {

        data.push({
          name: element?.month,
          "Incomplete Orders": element?.incomplete_orders,
          "Completed Orders": element?.completed_transactions,
        });
      }
    });
  }

  return (
    <>
      <Column>
        <Text className="not-italic text-gray_900 w-[auto]" variant="h4">
          Order Completion Summary
        </Text>
        <Text
          className="sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
          Completed and Incomplete Orders vs Time(Months)
        </Text>
      </Column>

      <ResponsiveContainer width="95%" height={height}>
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{
            top: 30,
            left: -30,
          }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Completed Orders" fill="#ffffff" />
          <Bar dataKey="Incomplete Orders" fill="#283660" />
          {/* <Bar dataKey="Profit" fill="rgb(35, 52, 103,0.5)" />
          <Bar dataKey="Revenue" fill="#ffffffcc" /> */}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}
GraphSales.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
GraphSales.defaultProps = {
  height: 400,
  width: 500,
};
