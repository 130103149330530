// import { Column } from "components/Column";
// import { Text } from "components/Text";
// import React from "react";
// import PropTypes from "prop-types";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   Legend,
//   ResponsiveContainer,
// } from "recharts";
// import { useGetUserStatsQuery } from "features/dashboard/dashboardApiSlice";
// import { selectCurrentRole } from "features/auth/authSlice";
// import { useSelector } from "react-redux";
// import { useGetInfoQuery } from "features/auth/authApiSlice";
// import { Tab, Tabs, Typography } from "@mui/material";
// import { Box } from "@mui/system";
// var hei, wid;
// if (window.innerWidth < 768) {
// } else if (window.innerWidth < 1024) {
// } else if (window.innerWidth < 1281) {
//   hei = 200;
//   wid = 600;
// } else if (window.innerWidth < 1441) {
//   hei = 400;
//   wid = 900;
// } else if (window.innerWidth < 1729) {
// }

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <Typography
//       component="div"
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       {...other}
//     >
//       <Box p={3}>{children}</Box>
//     </Typography>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `scrollable-auto-tab-${index}`,
//     "aria-controls": `scrollable-auto-tabpanel-${index}`,
//   };
// }

// export default function Graph({ width, height }) {
//   const role = localStorage.getItem("userRole");

//   const { data: usr, isLoading, isSuccess } = useGetUserStatsQuery();
//   const editable = structuredClone(usr);
//   const data = [],
//     yearlyData = [];

//   editable?.forEach((element) => {
//     if (
//       element?.users > 0 ||
//       element?.laboratories > 0 ||
//       element?.suppliers > 0 ||
//       element?.organizations > 0
//     ) {
//       if (role == "admin" || role == "soyo") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//         yearlyData.push({
//           name: element?.year,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//       } else if (role == "supplier") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           // Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           // Users: element?.users,
//         });
//       } else if (role == "client") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//       }
//     }
//   });
//   var title, subTitle;
//   if (role == "client") title = "Supplier Summary";
//   else if (role == "supplier") title = "Client Summary";
//   else title = "Users and Institutions Summary";
//   if (role == "client") subTitle = "New Suppliers vs Time(Months)";
//   else if (role == "supplier") subTitle = "New Clients vs Time(Months)";
//   else subTitle = "New Users and Institutions vs Time(Months)";
//   const [value, setValue] = React.useState(0);

//   function handleChange(event, newValue) {
//     setValue(newValue);
//   }

//   return (
//     <>
//       {" "}
//       <Column>
//         <Text className="not-italic text-gray_900 w-[auto]" variant="h4">
//           {title}
//           {/* {role == "client" ??  'Supplier Summary'}
//       {role == "supplier" ?? "Client Summary"}
//       {(role == "soyo" || role == "admin") ??
//         "Users and Institutions Summary"} */}
//         </Text>
//         <Text
//           className="sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
//           variant="body2"
//         >
//           {subTitle}
//         </Text>
//       </Column>
//       <div>
//         {/* <AppBar position="static" color="default"> */}
//         <Tabs
//           value={value}
//           onChange={handleChange}
//           indicatorColor="primary"
//           textColor="inherit"
//           variant="scrollable"
//           scrollButtons="auto"
//           aria-label=" auto tabs example"
//         >
//           {/* <Tab label="User" {...a11yProps(0)} /> */}
//           {/* <Tab label="Institution" {...a11yProps(1)} /> */}
//           <Tab label="Monthly" {...a11yProps(0)} />
//           <Tab label="Yearly" {...a11yProps(1)} />
//         </Tabs>
//         {/* </AppBar> */}
//         {/* <TabPanel value={value} index={0}>
//             <UserTabComponent />
//           </TabPanel> */}
//         {/* <TabPanel value={value} index={1}>
//         <CompanyTabComponent />
//       </TabPanel> */}
//         <TabPanel value={value} index={0}>
//           <ByMonth height={height} width={width} />
//         </TabPanel>
//         <TabPanel value={value} index={1}>
//           <ByYear height={height} width={width} />
//         </TabPanel>
//       </div>
//     </>
//   );
// }
// Graph.propTypes = {
//   height: PropTypes.number,
//   width: PropTypes.number,
// };
// Graph.defaultProps = {
//   height: 400,
//   width: 500,
// };

// export const ByMonth = ({ height, width }) => {
//   const {
//     data: userData,
//     isLoading: isLoadingInfo,
//     isError,
//     error,
//   } = useGetInfoQuery();
//   const { data: usr, isLoading, isSuccess } = useGetUserStatsQuery();
//   const editable = structuredClone(usr);
//   const data = [],
//     yearlyData = [];
//   const role = userData?.role;
//   editable?.forEach((element) => {
//     if (
//       element?.users > 0 ||
//       element?.laboratories > 0 ||
//       element?.suppliers > 0 ||
//       element?.organizations > 0
//     ) {
//       if (role == "admin" || role == "soyo") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//         yearlyData.push({
//           name: element?.year,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//       } else if (role == "supplier") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           // Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           // Users: element?.users,
//         });
//       } else if (role == "client") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//       }
//     }
//   });
//   var title, subTitle;
//   if (role == "client") title = "Supplier Summary";
//   else if (role == "supplier") title = "Client Summary";
//   else title = "Users and Institutions Summary";
//   if (role == "client") subTitle = "New Suppliers vs Time";
//   else if (role == "supplier") subTitle = "New Clients vs Time";
//   else subTitle = "New Users and Institutions vs Time";
//   return (
//     <>
//       <ResponsiveContainer width="95%" height={height}>
//         <BarChart
//           width={width}
//           height={height}
//           data={data}
//           margin={{
//             top: 30,
//             left: -30,
//           }}
//         >
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           {role != "client" ? (
//             <Bar dataKey="Laboratories" fill="#ffffff" />
//           ) : (
//             <></>
//           )}
//           {role != "supplier" ? (
//             <Bar dataKey="Suppliers" fill="#283660" />
//           ) : (
//             <></>
//           )}
//           {role != "client" ? (
//             <Bar dataKey="Organizations" fill="rgb(35, 52, 103,0.5)" />
//           ) : (
//             <></>
//           )}
//           {role != "supplier" && role != "client" ? (
//             <Bar dataKey="Users" fill="#ffffffcc" />
//           ) : (
//             <></>
//           )}
//         </BarChart>
//       </ResponsiveContainer>
//     </>
//   );
// };
// export const ByYear = ({ height, width }) => {
//   const {
//     data: userData,
//     isLoading: isLoadingInfo,
//     isError,
//     error,
//   } = useGetInfoQuery();
//   const { data: usr, isLoading, isSuccess } = useGetUserStatsQuery();
//   const editable = structuredClone(usr);
//   const data = [],
//     yearlyData = [];
//   const role = userData?.role;
//   editable?.forEach((element) => {
//     if (
//       element?.users > 0 ||
//       element?.laboratories > 0 ||
//       element?.suppliers > 0 ||
//       element?.organizations > 0
//     ) {
//       if (role == "admin" || role == "soyo") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//         yearlyData.push({
//           name: element?.year,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//       } else if (role == "supplier") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           // Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           // Users: element?.users,
//         });
//       } else if (role == "client") {
//         data.push({
//           name: element?.month,
//           Laboratories: element?.laboratories,
//           Suppliers: element?.suppliers,
//           Organizations: element?.organizations,
//           Users: element?.users,
//         });
//       }
//     }
//   });
//   var title, subTitle;
//   if (role == "client") title = "Supplier Summary";
//   else if (role == "supplier") title = "Client Summary";
//   else title = "Users and Institutions Summary";
//   if (role == "client") subTitle = "New Suppliers vs Time";
//   else if (role == "supplier") subTitle = "New Clients vs Time";
//   else subTitle = "New Users and Institutions vs Time";
//   return (
//     <>
//       <ResponsiveContainer width="95%" height={height}>
//         <BarChart
//           width={width}
//           height={height}
//           data={data}
//           margin={{
//             top: 30,
//             left: -30,
//           }}
//         >
//           <XAxis dataKey="name" />
//           <YAxis />
//           <Tooltip />
//           <Legend />
//           {role != "client" ? (
//             <Bar dataKey="Laboratories" fill="#ffffff" />
//           ) : (
//             <></>
//           )}
//           {role != "supplier" ? (
//             <Bar dataKey="Suppliers" fill="#283660" />
//           ) : (
//             <></>
//           )}
//           {role != "client" ? (
//             <Bar dataKey="Organizations" fill="rgb(35, 52, 103,0.5)" />
//           ) : (
//             <></>
//           )}
//           {role != "supplier" && role != "client" ? (
//             <Bar dataKey="Users" fill="#ffffffcc" />
//           ) : (
//             <></>
//           )}
//         </BarChart>
//       </ResponsiveContainer>
//     </>
//   );
// };

import { Column } from "components/Column";
import { Text } from "components/Text";
import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useGetUserStatsQuery } from "features/dashboard/dashboardApiSlice";
import { selectCurrentRole } from "features/auth/authSlice";
import { useSelector } from "react-redux";
import { useGetInfoQuery } from "features/auth/authApiSlice";
import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
var hei, wid;
if (window.innerWidth < 768) {
} else if (window.innerWidth < 1024) {
} else if (window.innerWidth < 1281) {
  hei = 200;
  wid = 600;
} else if (window.innerWidth < 1441) {
  hei = 400;
  wid = 900;
} else if (window.innerWidth < 1729) {
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Graph({ width, height,role }) {

  const { data: usr, isLoading, isSuccess } = useGetUserStatsQuery();
  const editable = structuredClone(usr);
  const data = [],
    yearlyData = [];

  editable?.forEach((element) => {
    if (
      element?.users > 0 ||
      element?.laboratories > 0 ||
      element?.suppliers > 0 ||
      element?.organizations > 0
    ) {
      if (role == "admin" || role == "soyo") {
        data.push({
          name: element?.month + ", " + element?.year,
          Laboratories: element?.laboratories,
          Suppliers: element?.suppliers,
          Organizations: element?.organizations,
          Users: element?.users,
        });
      } else if (role == "supplier") {
        data.push({
          name: element?.month + ", " + element?.year,
          Laboratories: element?.laboratories,
          // Suppliers: element?.suppliers,
          Organizations: element?.organizations,
          // Users: element?.users,
        });
      } else if (role == "client") {
        data.push({
          name: element?.month + ", " + element?.year,
          Laboratories: element?.laboratories,
          Suppliers: element?.suppliers,
          Organizations: element?.organizations,
          Users: element?.users,
        });
      }
    }
  });
  var title, subTitle;
  if (role == "client") title = "Supplier Summary";
  else if (role == "supplier") title = "Client Summary";
  else title = "Users and Institutions Summary";
  if (role == "client") subTitle = "New Suppliers vs Time(Months)";
  else if (role == "supplier") subTitle = "New Clients vs Time(Months)";
  else subTitle = "New Users and Institutions vs Time(Months)";

  return (
    <>
      {" "}
      <Column>
        <Text className="not-italic text-gray_900 w-[auto]" variant="h4">
          {title}
          {/* {role == "client" ??  'Supplier Summary'}
      {role == "supplier" ?? "Client Summary"}
      {(role == "soyo" || role == "admin") ??
        "Users and Institutions Summary"} */}
        </Text>
        <Text
          className="sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
          {subTitle}
        </Text>
      </Column>
      <div>
        {/* <AppBar position="static" color="default"> */}
        <ResponsiveContainer width="95%" height={height}>
          <BarChart
            width={width}
            height={height}
            data={data}
            margin={{
              top: 30,
              left: -30,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {role != "client" ? (
              <Bar dataKey="Laboratories" fill="#ffffff" />
            ) : (
              <></>
            )}
            {role != "supplier" ? (
              <Bar dataKey="Suppliers" fill="#283660" />
            ) : (
              <></>
            )}
            {role != "client" ? (
              <Bar dataKey="Organizations" fill="rgb(35, 52, 103,0.5)" />
            ) : (
              <></>
            )}
            {role != "supplier" && role != "client" ? (
              <Bar dataKey="Users" fill="#ffffffcc" />
            ) : (
              <></>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
Graph.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};
Graph.defaultProps = {
  height: 400,
  width: 500,
};
