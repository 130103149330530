import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {  TextField, InputAdornment,IconButton, Box} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { createTheme } from '@mui/material/styles';

import { Input } from 'components/Input';
import { Search } from '@material-ui/icons';

function SearchBar ({  placeholder, value, onChange }) {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#283660',
       
      },
      secondary: {
        
        main: '#ffffff',
    
      },
    },
  });
     return (
      // <ThemeProvider theme={theme}>
      <Box className=' bg-gray_50 border border-gray_50 border-solid rounded-radius845' sx={{ display: 'flex', alignItems: 'flex-end',height:'44px' ,backgroundColor:'rgba(40, 54, 96, 0.2)'}}>
       <Search className='my-auto mx-2' sx={{ color: '#ffffff' }} /> <input onChange={onChange} className='px-2 h-[40px] rounded-radius845' />
      {/* 
      <TextField size='medium' sx={{borderRadius: 30, padding:5, margin:5 }} color='secondary' value={value} onChange={onChange} name="search-form"  id="search-form" label={placeholder} variant="outlined" /> */}
    </Box>
    // </ThemeProvider>
      );
}
export default SearchBar;
