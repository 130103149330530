import { ErrorMessage } from "components/ErrorMessage";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export function Datepickerr({
  placeholder = "Select date",
  className,
  value,
  dateFormat = "dd/MM/yyyy",
  onChange,
  errors = [],
  ...restProps
}) {
  const [selectedDate, setSelectedDate] = React.useState(value);

  const onDateChange = (value) => {
    setSelectedDate(value);
    onChange?.(value.toISOString());
  };

  return (
    <>
      <div className={className}>
        <DatePicker
          dateFormat="yyyy-MM-dd"
          date
          placeholderText={placeholder}
          selected={selectedDate}
          onChange={onDateChange}
          {...restProps}
        />
      </div>
      <ErrorMessage errors={errors} />
    </>
  );
}

function DatePickerRange({
  startDate,
  endDate,
  placeholder = "Select range",
  className,
  dateFormat = "dd/MM/yyyy",
  onChange,
  errors = [],
  ...restProps
}) {
  const [startDateValue, setStartDate] = React.useState(startDate);
  const [endDateValue, setEndDate] = React.useState(endDate);

  const onDateChange = ([start, end]) => {
    setStartDate(start);
    setEndDate(end);
    onChange?.([start.toISOString(), end.toISOString()]);
  };

  return (
    <>
      <div className={className}>
        <DatePicker
          // className="bg-gray_50 border border-gray_50 border-solid 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"

          dateFormat={dateFormat}
          placeholderText={placeholder}
          startDate={startDateValue}
          endDate={endDateValue}
          onChange={onDateChange}
          selectsRange
          {...restProps}
        />
      </div>
      <ErrorMessage errors={errors} />
    </>
  );
}

Datepickerr.Range = DatePickerRange;
Datepickerr.DateTime = (props) => (
  <DatePicker dateFormat="dd/MM/yyyy h:mm aa" showTimeSelect {...props} />
);
Datepickerr.Time = (props) => (
  <DatePicker
    dateFormat="h:mm aa"
    showTimeSelect
    showTimeSelectOnly
    {...props}
  />
);
