import React from 'react'
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent,{
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PropTypes from "prop-types";


import { Text } from 'components/Text';
function TimelineElement({date,time,title,desc}) {
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flex: 0.2 
  //   }, 
  //   timeline: {
  //     padding: 0,
  //     margin: 0,
  //   },
  //   content: {
  //     padding: 0,
  //     margin: 0
  //   },
  //   secondaryTail: {
  //     backgroundColor: theme.palette.secondary.main,
  //   },
  // }));
  // const classes = useStyles();

  return (
    
    <TimelineItem sx={{margin:0, padding:0}}>
    <TimelineOppositeContent
   
    style={{ textAlign:'left'}}
      sx={{flex: 0.2}}
      width='100%'
   
      variant="body6"
      color="text.secondary"
    >
      <div className='w-[90px] sm:w-[80px] md:w-[60px] xl:w-[90px]'>
      <Text className="not-italic text-bluegray_800 text-left"
            variant="body1">
        {time} <br />
      </Text>
     
      <Text className="mt-[3px] not-italic text-bluegray_300"
                    variant="body2">{date}</Text> </div>
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineConnector sx={{ bgcolor: '#283660' }} />
      <TimelineDot
      sx={{ borderColor: '#283660' }}
      variant="outline"
      ></TimelineDot>
       <TimelineConnector sx={{ bgcolor: '#283660' }} />
    </TimelineSeparator>
    <TimelineContent className=''>
      <div className='w-[250px]'>
      <Text  className="not-italic text-bluegray_800"
              variant="body1" >
        {title} <br />
      </Text>{desc}
{/*     
     
      <Text className="mt-[3px] not-italic text-bluegray_300 w-[auto]"
                    variant="body2">{desc}</Text>  */}
                    </div>
    </TimelineContent>
  </TimelineItem>
  
  )
}
TimelineElement.propTypes = {
    data: PropTypes.string,
    time: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.object,
  }
  TimelineElement.defaultProps = {
    date:"Feb 21, 2022", 
    time:'10:00 AM ', 
    title:'New request. #XF-2357', 
    desc:'ICL - 100 Masks'   
  };

export default TimelineElement