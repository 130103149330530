import { createSlice } from '@reduxjs/toolkit';
const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null;
  const userId = localStorage.getItem('userId')
  ? localStorage.getItem('userId')
  : null
  const userRole = null;
//   localStorage.getItem('userRole')
//   ? localStorage.getItem('userRole')
//   : null
const authSlice = createSlice({
    name: 'auth',
    initialState: {user: userId, token: userToken, role: userRole },
    reducers:{
        setCredentials: (state, action) =>{
            const { id, token, role} = action.payload
            state.user = id
            state.token = token
            state.role = role
        },
        logOut: (state,action) => {
            localStorage.removeItem('userToken')
            //localStorage.removeItem('userRole')
            localStorage.removeItem('userId')
            state.user = null
            state.token = null
            state.role = null
            
        }
    }
})

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer
export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token
export const selectCurrentRole = (state) => state.auth.role