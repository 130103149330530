import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { Input,Column, Text, Button, Radio,RadioGroup } from 'components/'
import { Row } from 'components/Row'
import { Formik, Form } from 'formik'
import * as Yup from "yup";
import React, { useState } from 'react'
import { useGetGlobalItemClientQuery, useUpdateSoyoMarginMutation } from 'features/item/clientItemsSlice';
import { useGetReferenceItemQuery } from 'features/item/referenceItemApiSlice';
import { useGetUserQuery } from 'features/users/usersApiSlice';
import Toast from 'components/Toast';
function ReviewItemsModal({data}) {
  const [updateMargin, { isLoading }] = useUpdateSoyoMarginMutation();
  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const {
    data:owner,
    isLoadingOwner,
    isSuccessOwner,
    isErrorOwner,
    errorOwner
} = useGetUserQuery(data?.owner);
const {
  data:item,
  isLoadingItem,
  isSuccessItem,
  isErrorItem,
  errorItem
} = useGetReferenceItemQuery(data?.product.id);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ffffff',
    borderRadius:5,
    boxShadow: 24,
    p:5,
   
  };
  return (
    <Box className='w-[80%] md:w-[600px] h-[75vh]' sx={style}>
          <div style={{ overflow: "scroll", height: "65vh" }}>

      <Text
        
          className="not-italic text-gray_900 w-[auto]"
          variant="h2"
        >
        Review Item
        </Text>
        <Formik
        initialValues={{
          price: 0,
        }}
         validationSchema={Yup.object().shape({
          price: Yup.number().required("Price is required"),
        })}
        onSubmit={async (values, { resetForm }) => {
         var y={  soyo_price: values.price,
          id: data?.id};
          try {
            const itemData = await updateMargin({
              soyo_price: values.price,
              id: data?.id,
              soyo_margin_adjusted: true
            }).unwrap();
            setErrMsg("");
            setSuccessMsg("Successfully added to Global Inventory");
            resetForm();
          } catch (err) {
            if (!err) {
              setErrMsg("No Server Response");
            } else if (err.status === 400) {
              setErrMsg("Invalid Credentials");
            } else if (err.status === 401) {
              setErrMsg("Unauthorized");
            } else {
              setErrMsg("Failed to create an item");
            }
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
              <Column className='gap-3 mt-[30px]'>
              {errMsg && <Toast message={errMsg} />}
              {successMessage && (
                <Toast type="success" message={successMessage} />
              )}
              <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
                <Text className="not-italic text-gray_900 w-[150px] mb-2" variant="h6">
                    Supplier
                </Text>
               <Input 
              className="diasable w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="supplier"
              value={data?.owner}
              disabled='true'
            ></Input>
            </Row>
              <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
                <Text className="not-italic text-gray_900 w-[150px] mb-2" variant="h6">
                    Type of Item
                </Text>
               <Input 
              className="diasable w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="itemcategory"
              value={data?.product.type=='item'?'Item':'Kit'}
              disabled='true'
            ></Input>
            </Row>
            <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Item Name
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="itemname"
              value={item?.name}
              disabled='true'
              
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
                <Text className="not-italic text-gray_900 w-[150px] mb-2" variant="h6">
                 Brand
                </Text>
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="brand"
              value={data?.brand}

              disabled='true'
            ></Input>
            </Row>
            <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Price
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="price"
              value={data?.supplier_price}
              disabled='true'
            ></Input>
             </Row>
             
             <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
               Quantity
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"

              name="quantity"
              value={data?.quantity}
              disabled='true'
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
               Catalog Number
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="catalognumber"
              value={data?.catalog_number}
              disabled='true'
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 sm:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
               Profit Margin
              </Text>         
               <Input
               type='number'
              className="w-[100%]"
              wrapClassName=" 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px]  w-[300px]  sm:w-[300px]  xl:h-[41px] xl:mt-[8px]"
              name="profitmargin"
              placeholder="Profit Margin"
              {...formik.getFieldProps("price")}
            ></Input>
                             

             </Row>
           
         
           
                  <Button type='submit' className="w-[200px] mt-4" children={<Text>Add to Inventory</Text>}  ></Button>
                  </Column>
              </form>
          )}
        </Formik> 
        </div>
        </Box> )
}

export default ReviewItemsModal