import { Box } from "@mui/material";
import { Column } from "components/Column";
import BasicPagination from "components/Pagination";
import ListItem from "components/ListItem";
import { Text } from "components/Text";
import React, { useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import { Row } from "components/Row";
import { useGetTopSupplierQuery } from "features/dashboard/dashboardApiSlice";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { BounceLoader } from "react-spinners";
const libraries = ["places"];

function CompanyInfo({ id, limited = false }) {
  const mapRef = useRef();
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDJGH71uPHAv_QmPcZ3DzhfpEkyDcoL9ao",
    libraries,
  });
  const { data, isLoading, isSuccess } = useGetTopSupplierQuery(id);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,

    py: 5,
    px: 5,
  };
  var dataa = [];
  let list = dataa.map((item, index) => {
    return (
      <ListItem key={index} desc={item.company} name="" icon="producthunt" />
    );
  });
  return (
    <Box className="w-[80%] md:w-[600px] h-[83vh] sm:h-[75vh]" sx={style}>
      {isLoading || !isLoaded ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "50px",
          }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <div style={{ overflow: "scroll", height: "65vh" }}>
          <Column>
            <Text className="not-italic text-gray_900 w-[auto]" variant="h3">
              {data?.name}
            </Text>
            <Text
              className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
              variant="body2"
            >
              {data?.city +
                ", " +
                data?.country +
                ". " +
                data?.phone +
                ". " +
                data?.email}
            </Text>
            <Text
              className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
              variant="body2"
            >
              {data?.store_address}
            </Text>

            <div
              className="my-[10px]"
              style={{ height: "200px", width: "100%" }}
            >
              <GoogleMap
                mapContainerClassName="map-container mt-[3%]"
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                }}
                zoom={15}
                options={{ streetViewControl: false }}
                onLoad={onLoad}
                center={{
                  lat: data?.store_location?.latitude,
                  lng: data?.store_location?.longitude,
                }}
                onClick={(e) => {}}
              >
                <Marker
                  // icon={markerImage}
                  style={{ width: "24px", height: "24px" }}
                  position={{
                    lat: data?.store_location?.latitude,
                    lng: data?.store_location?.longitude,
                  }}
                />
              </GoogleMap>
            </div>
            {!limited && (
              <Row className="mt-[10px] grid grid-cols-2 w-[100%]">
                <Column>
                  <Text
                    className="not-italic text-gray_900 w-[auto]"
                    variant="h4"
                  >
                    Total Sale
                  </Text>
                  <Text
                    className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
                    variant="body3"
                  >
                    {data?.total_sale}
                  </Text>
                </Column>
                <Column>
                  <Text
                    className="not-italic text-gray_900 w-[auto]"
                    variant="h4"
                  >
                    Products Sold
                  </Text>
                  <Text
                    className="sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
                    variant="body3"
                  >
                    {data?.products_sold}
                  </Text>
                </Column>
              </Row>
            )}
          </Column>
        </div>
      )}
    </Box>
  );
}

CompanyInfo.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  desc: PropTypes.string,
  lat: PropTypes.number,
  lon: PropTypes.number,
};
CompanyInfo.defaultProps = {
  data: [
    { company: "Thermo", desc: 500 },
    { company: "NEB", desc: 500 },
    { company: "NEB", desc: 500 },
    { company: "NEB", desc: 500 },
  ],
  title: "International Clinical Laboratories",
  desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  lat: 8.9950991,
  lon: 38.7520883,
};

export default CompanyInfo;
