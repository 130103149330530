import React, { useState } from "react";

import { Column, Row, Img, Text, Input, Line, Stack, Button } from "components";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import { Form, Formik } from "formik";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CreateAccount from "components/Modals/CreateAccount";
import {
  Box,
  CircularProgress,
  IconButton,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import { useGetUserQuery } from "features/users/usersApiSlice";
import { BounceLoader } from "react-spinners";
import { useGetInstitutionQuery } from "features/institution/institutionApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useChangePasswordMutation,
  useCheckPasswordMutation,
} from "features/auth/authApiSlice";
import Toast from "components/Toast";
import Footer from "components/Footer";
import { PasswordInput } from "components/Password Input";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const EditAccountPage = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const [showPassword3, setShowPassword3] = useState(false);

  const handleClickShowPassword3 = () => {
    setShowPassword3(!showPassword3);
  };
  const handleMouseDownPassword3 = () => {
    setShowPassword3(!showPassword3);
  };
  const [open, setOpen] = React.useState(false);
  const [x, setX] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checkPassword, { isLoading: isLoadingCheck }] =
    useCheckPasswordMutation();
  const [errMsg, setErrMsg] = useState("");
  const [successMessage, setSuccessMsg] = useState("");
  const dispatch = useDispatch();
  const [updatePassword, { isLoading: isLoadingPass }] =
    useChangePasswordMutation();

  const { data, isLoadingg, isSuccess, isError, error } = useGetUserQuery(
    localStorage.getItem("userId")
  );
  const {
    data: instData,
    isLoading,
    isFetching,
  } = useGetInstitutionQuery(data ? data?.institution : skipToken);
  //   const {
  //     dataIns,
  //     isLoadinggIns,
  //     isSuccessIns,
  //     isErrorIns,
  //     errorIns,
  //     refetch
  // } = useGetInstitutionQuery(data?.institution);

  var menu = [
    {
      title: "Accounts",
      dropdown: true,
      entries: [
        // {
        //   title: "Add User",
        //   route_path: () => {
        //     handleOpen();
        //   },
        // },
        {
          title: "Users Management",
          route_path: () => {
            navigate("/dashboard/users");
          },
        },
        {
          title: "Institutions Management",
          route_path: () => {
            navigate("/dashboard/institutions");
          },
        },
      ],
      icon: "",
    },

    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/dashboard/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];
  const validatePassword = (values) => {
    let error = "";
    const passwordRegex = /(?=.*[0-9])/;
    if (!values) {
      error = "*Required";
    } else if (values.length < 8) {
      error = "*Password must be 8 characters long.";
    } else if (!passwordRegex.test(values)) {
      error = "*Invalid password. Must contain one number.";
    }
    return error;
  };
  const validateConfirmPassword = (pass, value) => {
    let error = "";
    if (pass && value) {
      if (pass !== value) {
        error = "Password not matched";
      }
    }
    return error;
  };
  const initialValues = {
    password: "",
    passwordOld: "",
    password2: "",
  };
  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "scroll" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateAccount />
      </Modal>

      <Header homePath="/dashboard" menus={menu} />

      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <>
          <div className="mx-[auto]  pb-[5rem] w-[70%]">
            <Text
              className="mb-[20px] not-italic text-gray_900 w-[auto]"
              as="h1"
              variant="h1"
            >
              Account Settings
            </Text>
            <Formik
              initialValues={initialValues}
              // validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                try {
                  const checkPass = await checkPassword({
                    password: values.passwordOld,
                  }).unwrap();
                  const institutionData = await updatePassword({
                    password: values.password,
                    id: data?.id,
                  }).unwrap();
                  setErrMsg("");
                  setSuccessMsg("Successfully updated password");
                  resetForm();
                } catch (err) {
                  if (!err) {
                    setErrMsg("No Server Response");
                  } else if (err.data?.password) setErrMsg(err?.data?.password);
                  else if (err.status === 400) {
                    setErrMsg("Invalid Credentials");
                  } else if (err.status === 401) {
                    setErrMsg("Unauthorized");
                  } else {
                    setErrMsg("Failed to change password");
                  }
                }
              }}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <Column className="gap-2">
                    {errMsg && <Toast message={errMsg} />}
                    {successMessage && (
                      <Toast type="success" message={successMessage} />
                    )}
                    {/* <Row className='items-center'> */}
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Username
                    </Text>
                    <Input
                      className="diasable w-[100%]"
                      wrapClassName="bg-gray_400 text-gray_900 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="username"
                      disabled="true"
                      value={data?.username}
                    ></Input>
                    {/* </Row> */}
                    {/* <Row className="items-center"> */}
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Full Name
                    </Text>
                    <Input
                      className="w-[100%]"
                      wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="fullname"
                      disabled="true"
                      value={data?.first_name}
                    ></Input>
                    {/* </Row> */}
                    {/* <Row className='items-center'> */}
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Email Address
                    </Text>
                    <Input
                      className="w-[100%]"
                      wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="emailaddress"
                      disabled="true"
                      value={data?.last_name}
                    ></Input>
                    {/* </Row> */}
                    {/* <Row className="items-center"> */}
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Phone Number
                    </Text>
                    <Input
                      className="w-[100%]"
                      wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="phonenumber"
                      disabled="true"
                      value={data?.phone}
                    ></Input>
                    {/* </Row>     */}
                    {/* <Row className='items-center'> */}
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Position
                    </Text>
                    <Input
                      className="w-[100%]"
                      wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="position"
                      disabled="true"
                      value={data?.position}
                    ></Input>
                    {/* </Row> */}
                    {/* <Row className="items-center"> */}

                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Institution
                    </Text>
                    <Input
                      className="w-[100%]"
                      wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="institution"
                      disabled="true"
                      value={instData?.name}
                    ></Input>
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Old Password
                    </Text>
                    <PasswordInput
                      className="w-[100%]"
                      wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="passwordOld"
                      {...formik.getFieldProps("passwordOld")}
                      type={showPassword ? "text" : "password"}
                      suffix={
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                    ></PasswordInput>

                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      New Password
                    </Text>

                    <PasswordInput
                      className="w-[100%]"
                      wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="password"
                      validate={validatePassword}
                      type={showPassword2 ? "text" : "password"}
                      suffix={
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                      {...formik.getFieldProps("password")}
                    ></PasswordInput>
                    {formik.touched?.password && formik.errors?.password ? (
                      <div>
                        <Text
                          className="not-italic text-red_700_cc w-[auto]"
                          variant="body2"
                        >
                          {formik.errors.password}
                        </Text>
                      </div>
                    ) : null}
                    {/* </Row> */}
                    {/* <Row className="items-center"> */}
                    <Text
                      className="not-italic text-gray_900 w-[200px]"
                      variant="h6"
                    >
                      Confirm new Password
                    </Text>
                    <PasswordInput
                      className="w-[100%]"
                      wrapClassName="2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] lg:mt-[6px] w-[100%] xl:h-[41px] xl:mt-[8px]"
                      name="password2"
                      type={showPassword3 ? "text" : "password"}
                      suffix={
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword3}
                          onMouseDown={handleMouseDownPassword3}
                        >
                          {showPassword3 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      }
                      {...formik.getFieldProps("password2")}
                      validate={(value) =>
                        validateConfirmPassword(formik.values.password, value)
                      }
                    ></PasswordInput>
                    {formik.touched?.password2 && formik.errors?.password2 ? (
                      <div>
                        <Text
                          className="not-italic text-red_700_cc w-[auto]"
                          variant="body2"
                        >
                          {formik.errors.password2}
                        </Text>
                      </div>
                    ) : null}
                    {/* </Row> */}
                    <Button
                      type="submit"
                      className="w-[200px] mt-4"
                      children="Save Changes"
                    ></Button>
                  </Column>
                </form>
              )}
            </Formik>
          </div>
        </>
      )}
      <Footer />
    </>
  );
};

export default EditAccountPage;
