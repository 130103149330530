import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import Timeline from '@mui/lab/Timeline';
import {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import TimelineElement from "../../Timeline/TimelineElement/index";
import { Box } from '@mui/material';
import BasicPagination from 'components/Pagination';
import { Column } from 'components/Column';
import { Text } from 'components/Text';
import { useGetOrdersClientQuery } from 'features/order/ordersApiSlice';
import moment from 'moment';
import InstitutionLookUp from 'components/LookUpElements/institution';
import Paginationn from 'components/Pagination';
import { Row } from 'components/Row';
import SearchBar from 'components/Search';
function TimelineModal() {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(5);
  const { data: orders, isLoading, isSuccess } = useGetOrdersClientQuery();
  const [myApi, setMyApi] = useState([]);
  const [dataa, setDataa] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = myApi?.slice(indexOfFirstData, indexOfLastData);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if(isSuccess){
    setDataa(orders);
    let myApii;
    if (searchQuery == "") {
      myApii = orders;
    } else {
      myApii = orders?.filter((item) =>
        item.status.toLowerCase().includes(searchQuery) ||  
        item.id.toString().toLowerCase().includes(searchQuery) ||
        moment(item?.created_at).format("YYYY-MM-DD").includes(searchQuery) ||
        moment(item?.created_at).format("h:mm a").includes(searchQuery)

      );
    }
    setMyApi(renderData(myApii));
  }
    // setLoading(false);
  }, [orders, searchQuery]);
  // let list = currentData?.map((item, index) => {

    
    
  // });
  const renderData = (dataa) => {
    return dataa?.map((item, idx) => {
      return (
        <TimelineElement
          date={moment(item?.created_at).format("YYYY-MM-DD")}
          time={moment(item?.created_at).format("h:mm a")}
          desc={<InstitutionLookUp id={item?.client_institution} timeline={true} />}
          title={"Order #"+ item?.id+". "+ item?.status}
        />
      );
    });
  };
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        borderRadius:5,
        boxShadow: 24,
        px:3,
        py:5
      };
  return (
    <Box className='w-[80%] sm:w-[60%] md:w-[600px] h-[75vh]' sx={style}>
            <div style={{ overflow: "scroll", height: "65vh" }}>

         <Column>
         
         <Row className="justify-between">
          <Column>
        <Text
          className="not-italic text-gray_900 w-[auto] ml-[32px]"
          variant="h3"
        >
        Timeline
        </Text>
        <Text
          className="ml-[32px] sm:mt-[1px] md:mt-[2px] lg:mt-[3px] xl:mt-[4px] 2xl:mt-[5px] 3xl:mt-[6px] not-italic text-bluegray_300 w-[auto]"
          variant="body2"
        >
          Order History Updates
        </Text>
        </Column>
  
        <SearchBar
              onChange={(event) =>
                setSearchQuery(event.target.value.toLowerCase())
              }
            />
                  </Row>
    <Timeline
    sx={{
     [`& .${timelineOppositeContentClasses.root}`]: {
       flex: 0.2,
     },
   }}
   >
       {currentData}
   </Timeline>
   <Paginationn
            dataPerPage={dataPerPage}
            totaldata={myApi?.length}
            paginate={paginate}
          />   </Column>
          </div>
   </Box>
  )
}
TimelineModal.propTypes = {
    data: PropTypes.array,
  }
TimelineModal.defaultProps = {
    data: [{date:"Feb 21, 2022", time:'10:00 AM ', title:'New request. #XF-2357', desc:'ICL - 100 Masks'},{date:"Feb 21, 2022", time:'10:00 AM ', title:'New order. #XF-2357', desc:'ICL - 100 Masks'},{date:"Feb 21, 2022", time:'10:00 AM ', title:'New transaction. #XF-2357', desc:'ICL - 100 Masks'},{date:"Feb 21, 2022", time:'10:00 AM ', title:'New request. #XF-2357', desc:'ICL - 100 Masks'},{date:"Feb 21, 2022", time:'10:00 AM ', title:'New request. #XF-2357', desc:'ICL - 100 Masks'},{date:"Feb 21, 2022", time:'10:00 AM ', title:'New request. #XF-2357', desc:'ICL - 100 Masks'},{date:"Feb 21, 2022", time:'10:00 AM ', title:'New request. #XF-2357', desc:'ICL - 100 Masks'}],
   
  };
export default TimelineModal
