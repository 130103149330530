import React from "react";

import {
  Column,
  Row,
  Img,
  Text,
  Button,
  Stack,
  Radio,
  Line,
  RadioGroup,
  Grid,
} from "components";
import { useNavigate } from "react-router-dom";
import Header from "components/Header";
import TopList from "components/TopList";
import Timelines from "components/Timeline";
import RevenueList from "components/RevenueList";
import StatBoxes from "components/StatBoxes";
import Graph from "components/Graph";
import ProductList from "components/Modals/ProductList";
import TimelineModal from "components/Modals/Timeline";
import SalesReport from "components/Modals/Sales";
import CompanyInfo from "components/Modals/CompanyInfo";
import InstitutionList from "components/Modals/InstitutionList";
import ProductCategory from "components/Modals/ProductCategory";
import { Box, Modal } from "@mui/material";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import AddItemsModal from "components/Modals/AddItem";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import Footer from "components/Footer";
import ClientInfo from "components/Modals/CompanyInfo/ClientInfo";
import LabInfo from "components/Modals/CompanyInfo/lab";
import {
  useGetTopLaboratoriesQuery,
  useGetTopOrganizationsQuery,
  useGetTopSuppliersQuery,
} from "features/dashboard/dashboardApiSlice";
import RevenueListSupplier from "components/RevenueList/supplier";
import SalesReportSupplier from "components/Modals/Sales/supplier";
import { BounceLoader } from "react-spinners";

const SupplierHome = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [x, setX] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { data, isLoading, isSuccess } = useGetTopSuppliersQuery();
  const editable = structuredClone(data);
  const {
    data: dataOrg,
    isLoading: isLoadingOrg,
    isSuccess: isSuccessOrg,
  } = useGetTopOrganizationsQuery();
  const editableOrg = structuredClone(dataOrg);
  const {
    data: dataLab,
    isLoading: isLoadingLab,
    isSuccess: isSuccessLab,
  } = useGetTopLaboratoriesQuery();
  const editableLab = structuredClone(dataLab);

  var listSuppliers = [],
    listOrganizations = [],
    listLaboratories = [],
    listSupplierModal = [],
    listOrgModal = [],
    listLabModal = [];
  if (isSuccess) {
    for (let index = 0; index < editable?.length; index++) {
      if (index < 4) {
        listSupplierModal.push({
          desc: editable[index]?.name,
          name: editable[index]?.products_sold + " sold",
          icon: "building",
          id: editable[index]?.id,
        });
        listSuppliers.push({
          company: editable[index]?.name,
          desc: editable[index]?.products_sold + " sold",
          clicked: () => {
            chooseModal(8, editable[index].id);
            handleOpen();
          },
        });
      } else {
        listSupplierModal.push({
          desc: editable[index]?.name,
          name: editable[index]?.products_sold + " sold",
          id: editable[index]?.id,
          icon: "building",
        });
      }
    }
  }
  if (isSuccessOrg) {
    for (let index = 0; index < editableOrg?.length; index++) {
      if (index < 4) {
        listOrgModal.push({
          desc: editableOrg[index]?.name,
          name: editableOrg[index]?.products_bought + " bought",
          id: editableOrg[index]?.id,
          icon: "building",
        });
        listOrganizations.push({
          company: editableOrg[index]?.name,
          desc: editableOrg[index]?.products_bought + " bought",
          clicked: () => {
            chooseModal(14, editableOrg[index].id);
            handleOpen();
          },
        });
      } else {
        listOrgModal.push({
          desc: editableOrg[index]?.name,
          name: editableOrg[index]?.products_bought + " bought",
          icon: "building",
          id: editableOrg[index]?.id,
        });
      }
    }
  }
  if (isSuccessLab) {
    for (let index = 0; index < editableLab?.length; index++) {
      if (index < 4) {
        listLabModal.push({
          desc: editableLab[index]?.name,
          name: editableLab[index]?.products_bought + " bought",
          icon: "lab",
          id: editableLab[index]?.id,
        });
        listLaboratories.push({
          company: editableLab[index]?.name,
          desc: editableLab[index]?.products_bought + " bought",
          clicked: () => {
            chooseModal(15, editableLab[index].id);
            handleOpen();
          },
        });
      } else {
        listLabModal.push({
          desc: editableLab[index]?.name,
          name: editableLab[index]?.products_bought + " bought",
          icon: "lab",
          id: editableLab[index]?.id,
        });
      }
    }
  }
  var statEntries = [
    {
      title: "Suppliers",
      number: "14",
      onClick: () => {
        chooseModal(10);
        handleOpen();
      },
    },
    {
      title: "Global Inventory",
      number: "14679",
      onClick: () => {
        chooseModal(9);
        handleOpen();
      },
    },
    {
      title: "Laboratories",
      number: "16",
      onClick: () => {
        chooseModal(12);
        handleOpen();
      },
    },
    {
      title: "Organizations",
      number: "12",
      onClick: () => {
        chooseModal(11);
        handleOpen();
      },
    },
  ];
  var listInstitutionModal = [
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
    { desc: "Ibuprofen", name: "", icon: "lab" },
  ];

  function chooseModal(idx, id) {
    switch (idx) {
      case 2:
        setX(<ProductList />);
        break;
      case 3:
        setX(<ProductList />);
        break;
      case 4:
        setX(<TimelineModal />);
        break;
      case 5:
        setX(<SalesReportSupplier />);
        break;
      case 6:
        setX(<ProductList />);
        break;
      case 7:
        setX(<ProductCategory />);
        break;
      case 8:
        setX(<CompanyInfo />);
        break;
        // case 9:
        //   setX(<ViewItems />);
        break;
      case 10:
        setX(
          <InstitutionList
            title="Total Suppliers"
            subTitle={editable?.length + " Total Suppliers"}
            data={listSupplierModal}
          />
        );
        break;
      case 11:
        setX(
          <InstitutionList
            title="Total Organizations"
            subTitle={editableOrg?.length + " Total Organizations"}
            data={listOrgModal}
          />
        );
        break;
      case 12:
        setX(
          <InstitutionList
            title="Total Laboratories"
            subTitle={editableLab?.length + " Total Laboratories"}
            data={listLabModal}
          />
        );
        break;

      case 13:
        setX(<AddItemsModal />);
        break;
      case 14:
        setX(<ClientInfo id={id} limited={true} />);
        break;
      case 15:
        setX(<LabInfo id={id} limited={true} />);
        break;
      default:
        setX(<CompanyInfo />);
    }
  }
  var menu = [
    {
      title: "Items",
      dropdown: true,
      entries: [
        { title: "Add Item", route_path: () => navigate("/supplier/additem") },

        { title: "My Items", route_path: () => navigate("/supplier/myitems") },
        //  {title: 'Add Item', route_path:()=>{chooseModal(13);handleOpen()}},
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/supplier/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];

  return (
    <>
      {isLoading || isLoadingLab || isLoadingOrg ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            height: "50px",
          }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            sx={{ overflow: "scroll" }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {x ?? <AddItemsModal />}
          </Modal>
          <Header homePath="/supplier" menus={menu} />
          <Row class="mx-[auto] w-[75%] sm:w-[80%] mt-[20px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <TopList
              icon="lab"
              entries={listLaboratories}
              title="Top 4 Laboratories"
              subtitle={editableLab?.length + " Total Laboatories"}
              seeMore={() => {
                chooseModal(12);
                handleOpen();
              }}
            />
            <TopList
              icon="building"
              entries={listOrganizations}
              title="Top 4 Organizations"
              subtitle={editableOrg?.length + " Total Organizations"}
              seeMore={() => {
                chooseModal(11);
                handleOpen();
              }}
            />

            <div class="xl:ml-[26px]">
              <RevenueListSupplier
                title={"Sales Summary"}
                seeMore={() => {
                  chooseModal(5);
                  handleOpen();
                }}
              />{" "}
            </div>

            {/* <Timelines
          seeMore={() => {
            chooseModal(4);
            handleOpen();
          }}
        /> */}
          </Row>
          <div class="w-[75%] sm:w-[80%] pb-[5rem] mx-[auto] md:w-[80%] lg:w-[80%] xl:w-[80%] mt-[20px] md:mt-[20px] lg:mt-[20px] xl:mt-[20px]">
            <Graph role={"supplier"} />
          </div>
        </>
      )}
      <Footer />
    </>
  );
};
export default SupplierHome;
