import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentRole, selectCurrentToken } from "./authSlice";
import useAuth from "hooks/useAuth";
import { useGetInfoQuery } from "./authApiSlice";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { BounceLoader } from "react-spinners";

const RequireAuth = ({ allowedRole }) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    fetch("https://test.api.soyo.testserver.awuraplc.org/users/info/", {
      //   fetch("https://api.soyo.soyoafricabiotech.com/users/info/", {
      method: "GET",
      headers: new Headers({
        authorization: `Token ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {});
  }, []);
  var role = data?.role;

  const location = useLocation();
  return (
    <>
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : //) : role != undefined ? (
      role === allowedRole ? (
        <Outlet />
      ) : token ? (
        role === undefined ? (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              height: "50px",
            }}
          >
            <BounceLoader
              color="#283660"
              // className="bg-green_400"
            />
          </Box>
        ) : (
          <Navigate
            to="/error"
            state={{
              from: location,
              errorCode: 401,
              buttonText: "Back to Home",
              errorMessage: "Unauthorized to access this page",
              role: role,
            }}
            replace
          />
        )
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  );
};

export default RequireAuth;
