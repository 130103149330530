import { ErrorMessage } from "../../components/ErrorMessage";
import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const variants = {
  OutlineGray50: "bg-gray_50 border border-gray_50 border-solid",
  FillGray50: "bg-gray_50",
  srcOutlineGreen400cc: "bg-gray_51 border border-green_400_cc border-solid",
};
const shapes = {
  RoundedBorder8: "rounded-radius845",
  srcCircleBorder20: "rounded-radius20",
};
const sizes = {
  sm: "lg:pb-[13px] xl:pb-[16px] pb-[19px] 3xl:pb-[22px] xl:pt-[10px] pt-[12px] 3xl:pt-[14px] lg:pt-[8px] xl:px-[10px] px-[12px] 3xl:px-[14px] lg:px-[8px]",
  md: "lg:p-[10px] xl:p-[13px] p-[15px] 3xl:p-[18px]",
  lg: "pb-[11px] 3xl:pb-[13px] lg:pb-[7px] xl:pb-[9px] lg:pt-[12px] xl:pt-[16px] pt-[18px] 3xl:pt-[21px] px-[11px] 3xl:px-[13px] lg:px-[7px] xl:px-[9px]",
  smSrc: "p-[11px] 3xl:p-[13px] lg:p-[7px] xl:p-[9px]",
};

const Input = React.forwardRef(
  (
    {
      wrapClassName = "",
      className = "",
      name,
      placeholder,
      type = "text",
      children,
      errors = [],
      label = "",
      prefix,
      suffix,
      shape,
      variant,
      size,
      disabled,
      ...restProps
    },
    ref
  ) => {
    return (
      <>
        <div
          className={`${wrapClassName} ${shapes[shape] || ""} ${
            variants[variant] || ""
          } ${sizes[size] || ""}`}
        >
          {!!label && label}
          {!!prefix && prefix}
          <Field
          disabled={disabled}
            ref={ref}
            className={`${className} bg-transparent border-0`}
            type={type}
            name={name}
            placeholder={placeholder}
            {...restProps}
          />
          {!!suffix && suffix}
        </div>
        {!!errors && <ErrorMessage errors={errors} />}
      </>
    );
  }
);

Input.propTypes = {
  wrapClassName: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  shape: PropTypes.oneOf(["RoundedBorder8", "srcCircleBorder20"]),
  variant: PropTypes.oneOf([
    "OutlineGray50",
    "FillGray50",
    "srcOutlineGreen400cc",
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "smSrc"]),
};
Input.defaultProps = {
  wrapClassName: "",
  className: "",
  name: "",
  placeholder: "",
  disabled: false,
  type: "text",
  shape: "RoundedBorder8",
  variant: "OutlineGray50",
  size: "",
};

export { Input };
