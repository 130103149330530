import React, { forwardRef, useState } from "react";
import { Button, Column, Row, Text } from "components";
import {
  Box,
  Modal,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/Card/index";
import Header from "components/Header/index.jsx";
import BasicPagination from "../../../components/Pagination/index";
import EditAccounts from "components/Modals/EditAccount/index.js";
import {
  faBoxesStacked,
  faCheckCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddGlobalItem from "components/Modals/CreateGlobalItem";
import GenerateAlert from "components/Alerts/GenerateOffer";
import BarLoader from "react-spinners/BarLoader";
import Footer from "components/Footer";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  DoneOutline,
  InfoOutlined,
  LocalShippingOutlined,
  HourglassEmptyOutlined,
  LocationOnOutlined,
} from "@material-ui/icons";

import {
  useGetOffersClientQuery,
  useGetOrdersClientQuery,
  useUpdateOrderStatusMutation,
} from "features/order/ordersApiSlice";
import MaterialTable from "material-table";
import { HourglassBottom } from "@mui/icons-material";
import {
  useGetInstitutionQuery,
  useGetInstitutionsQuery,
} from "features/institution/institutionApiSlice";
import { useGetAllUsersQuery } from "features/users/usersApiSlice";
import UserLookUp from "components/LookUpElements/user";
import InstitutionLookUp from "components/LookUpElements/institution";
import { BounceLoader } from "react-spinners";
import OrderInfo from "components/Modals/OrderInfo";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const ViewOrdersPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [open, setOpen] = React.useState(false);
  let [loadingInProgress, setLoading] = React.useState(false);
  const selectedRow = React.useRef([]);
  const [successMessage, setSuccessMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const [OpenDia, setOpenDia] = React.useState(false);
  const [idFulfilled, setId] = useState("");
  const handleClickClose = () => {
    setOpenDia(false);
  };
  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetOffersClientQuery();
  const [updateStatus, { isLoading: isLoadingStatus }] =
    useUpdateOrderStatusMutation();

  const editable = structuredClone(records);

  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Client User",
      field: "client_user",
      align: "left",
      render: (rowData) => <UserLookUp id={rowData.client_user} />,
      filterPlaceholder: "Filter",
    },
    {
      title: "Client Institution",
      field: "client_institution",
      align: "left",
      render: (rowData) => (
        <InstitutionLookUp id={rowData.client_institution} />
      ),

      filterPlaceholder: "Filter",
    },
    {
      title: "Status",
      field: "status",
      align: "left",
      lookup: {
        accepted: "Accepted",
        delivery: "Delivery",
        placed: "Placed",
        fulfilled: "Fulfilled",
        processing: "Processing",
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Deliver to ",
      field: "deliver_to",
      align: "left",

      filterPlaceholder: "Filter",
    },
    {
      title: "Deliver by ",
      field: "deliver_by",
      align: "left",

      filterPlaceholder: "Filter",
    },
    // {
    //   title: "Item Type",
    //   field: "product?.type",
    //   align: "left",
    //   lookup: { item: "Item", kit: "Kit" },

    //   filterPlaceholder: "Filter",
    // },

    // {
    //   title: "Catalog Number",
    //   field: "catalog_number",
    //   align: "left",
    //   // type: "numeric",

    //   validate: (rowData) => {
    //     if (
    //       rowData.catalog_number === undefined ||
    //       rowData.catalog_number == ""
    //     )
    //       return "Required";
    //     else return true;
    //   },
    //   filterPlaceholder: "Filter",
    // },
    // {
    //   title: "Brand",
    //   field: "brand",
    //   align: "left",
    //   emptyValue: () => <em>Not Applicable</em>,
    //   // type: "strin",

    //   // validate: (rowData) => {
    //   //   if (rowData.brand === undefined || rowData.brand == "")
    //   //     return "Required";
    //   //   else return true;
    //   // },
    //   filterPlaceholder: "Filter",
    // },
    // {
    //   title: "Description",
    //   field: "description",
    //   align: "left",
    //   // type: "numeric",

    //   // validate: (rowData) => {
    //   //   if (rowData.description === undefined || rowData.description == "")
    //   //     return "Required";
    //   //   else return true;
    //   // },
    //   filterPlaceholder: "Filter",
    // },
  ];
  function Timeout(delay) {
    return new Promise((res) => setTimeout(res, delay));
  }

  const handleClick = (rows) => {
    selectedRow.current = rows;
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  var menu = [
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "View Orders", route_path: () => navigate("/officer/orders") },
        {
          title: "Order History",
          route_path: () => navigate("/officer/orderhistory"),
        },
      ],
      icon: "",
    },
    {
      title: "Items",
      dropdown: true,
      entries: [
        {
          title: "Add Global Item",
          route_path: () => {
            chooseModal(1);
            handleOpen();
          },
        },
        {
          title: " Global Inventory",
          route_path: () => navigate("/officer/globalinventory"),
        },
        {
          title: " Reference List",
          route_path: () => navigate("/officer/referenceList"),
        },

        {
          title: "Review Items",
          route_path: () => navigate("/officer/reviewitems"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/officer/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
  ];
  function chooseModal(idx, info, name) {
    switch (idx) {
      case 1:
        setX(<AddGlobalItem />);
        break;
      case 2:
        setX(<OrderInfo info={info} name={name} />);
        break;
    }
  }
  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "fixed" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <AddGlobalItem />}
      </Modal>
      <Dialog
        open={OpenDia}
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            border: "2px solid #ffffff",
            borderRadius: 3,
            boxShadow: 24,
          }}
          className="p-3"
        >
          <DialogTitle id="alert-dialog-title">
            <Text variant="h4">
              Are you sure that this order is fulfilled?.{" "}
            </Text>
          </DialogTitle>
          {/* <DialogContent className="items-center justify-center justify-self-center">
                      <Column className="items-center mx-[auto]">
                        <FontAwesomeIcon
                          type="outline"
                          className="px-[auto] text-center self-center"
                          color="#283660"
                          fontSize={30}
                          width={40}
                          icon={faCheckCircle}
                        />
                      </Column>
                    </DialogContent> */}
          <DialogActions>
            <Row className="justify-center gap-5 w-[100%]">
              <Button
                className="w-[25%]"
                onClick={async () => {
                  try {
                    const itemData = await updateStatus({
                      id: idFulfilled,
                      status: "fulfilled",
                    }).unwrap();
                    handleClickClose();
                    // setSuccessMsg("");
                  } catch (err) {
                    if (!err) {
                      setErrMsg("No Server Response");
                    } else if (err.status === 400) {
                      setErrMsg("Invalid Credentials");
                    } else if (err.status === 401) {
                      setErrMsg("Unauthorized");
                    } else {
                      setErrMsg("Failed to changhe status");
                    }
                  }
                }}
              >
                Yes
              </Button>
              <Button
                className="w-[25%]"
                onClick={() => {
                  handleClickClose();
                }}
                autoFocus
              >
                No
              </Button>
            </Row>
          </DialogActions>
        </div>
      </Dialog>
      <Header homePath="/officer" menus={menu} />

      <Column className="mx-[auto] w-[90%] pb-[5rem] sm:w-[90%] md:w-[80%] lg:w-[80%] xl:w-[80%]">
        <Row className="mb-[3%] content-end grid grid-cols-2">
          <Text variant="h1" className=" not-italic text-gray_900">
            Pending Orders
          </Text>
        </Row>
        <Column className="grid mb-2">
          {isLoading || isLoadingStatus ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                height: "50px",
              }}
            >
              <BounceLoader
                color="#283660"
                // className="bg-green_400"
              />
            </Box>
          ) : (
            <MaterialTable
              options={{
                showTitle: false,
                sorting: true,
                emptyRowsWhenPaging: false,
                search: true,
                filtering: true,
                pageSizeOptions: [5, 10, 15],
                exportButton: true,
                columnResizable: true,
                exportAllData: true,
                // selection: true,
                showSelectAllCheckbox: true,
                showTextRowsSelected: true,

                selectionProps: (rowData) => ({ color: "primary" }),
                headerSelectionProps: (header) => ({ color: "primary" }),
                searchFieldAlignment: "left",
                grouping: true,
                columnsButton: true,
                rowStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                  color: "#181c32",
                },

                headerStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                  color: "#181c32",
                  fontWeight: "bolder",
                },
                filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
                detailPanelColumnStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                },
                actionsColumnIndex: 10,
              }}
              actions={[
                (rowData) => {
                  return {
                    icon: LocationOnOutlined,
                    tooltip: "Location on Map",
                    // disabled: rowData.order == "item",
                    // hidden: rowData.status === "active",
                    onClick: (event, rowData) => {
                      chooseModal(
                        2,
                        rowData.deliver_to_location,
                        rowData.deliver_to
                      );
                      handleOpen();
                    },
                  };
                },
                {
                  icon: HourglassBottom,

                  tooltip: "Processing",
                  onClick: async (event, rowData) => {
                    try {
                      const itemData = await updateStatus({
                        id: rowData.id,
                        status: "processing",
                      }).unwrap();
                      setErrMsg("");
                      // setSuccessMsg("");
                    } catch (err) {
                      if (!err) {
                        setErrMsg("No Server Response");
                      } else if (err.status === 400) {
                        setErrMsg("Invalid Credentials");
                      } else if (err.status === 401) {
                        setErrMsg("Unauthorized");
                      } else {
                        setErrMsg("Failed to changhe status");
                      }
                    }
                    // console.log(rowData.id);
                    // chooseModal(1, rowData);
                    // handleOpen();
                  },
                },
                {
                  icon: LocalShippingOutlined,

                  tooltip: "Delivering",
                  onClick: async (event, rowData) => {
                    try {
                      const itemData = await updateStatus({
                        id: rowData.id,
                        status: "delivery",
                      }).unwrap();
                      setErrMsg("");
                      // setSuccessMsg("");
                    } catch (err) {
                      if (!err) {
                        setErrMsg("No Server Response");
                      } else if (err.status === 400) {
                        setErrMsg("Invalid Credentials");
                      } else if (err.status === 401) {
                        setErrMsg("Unauthorized");
                      } else {
                        setErrMsg("Failed to changhe status");
                      }
                    }
                    // chooseModal(1, rowData);
                    // handleOpen();
                  },
                },
                {
                  icon: DoneOutline,

                  tooltip: "Fulfilled",
                  onClick: async (event, rowData) => {
                    setId(rowData.id);
                    setOpenDia(true);
                    // chooseModal(1, rowData);
                    // handleOpen();

                    // chooseModal(1, rowData);
                    // handleOpen();
                  },
                },
              ]}
              onSelectionChange={(e) => {
                handleClick(e);
              }}
              columns={columns}
              data={editable}
              icons={tableIcons}
            />
          )}
        </Column>
      </Column>
      <Footer />
    </>
  );
};

export default ViewOrdersPage;
