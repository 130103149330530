import { Box, InputLabel, MenuItem, Select } from '@mui/material';
import { Input,Column, Text, Button, Radio,RadioGroup } from 'components/'
import { Row } from 'components/Row'
import { useGetInstitutionQuery } from 'features/institution/institutionApiSlice';
import { Formik, Form } from 'formik'
import React from 'react'
function ViewAccount({username,email, fullName, phone, category, institution,position}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #ffffff',
    borderRadius:5,
    boxShadow: 24,
    p:5,
   
  };
  const {
    data,
    isLoadingg,
    isSuccess,
    isError,
    error
} = useGetInstitutionQuery(institution);
  return (
    <Box className='w-[80%] md:w-[600px]' sx={style}>
      <Text
        
          className="not-italic text-gray_900 w-[auto]"
          variant="h2"
        >
      User Details
        </Text>
<Formik
          initialValues={{
            username: "",
            password: "",
          }}
          // validationSchema={loginSchema}
          // onSubmit={handleSubmit}
        >
          {(props) => (
            <div component={Form} noValidate>
              <Column className='gap-3 mt-[30px]'>
              <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
                <Text className="not-italic text-gray_900 mb-2" variant="h6">
                    Username
                </Text>
               <Input 
              className="diasable w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="username"
              value={username}
              disabled='true'
            ></Input>
            </Row>
            <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Full Name
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="fullname"
              value={fullName}
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
                <Text className="not-italic text-gray_900 w-[150px] mb-2" variant="h6">
                    Email Address
                </Text>
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="emailaddress"
              value={email}
            ></Input>
            </Row>
            <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Phone Number
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="phonenumber"
              value={phone}
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Category
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="category"
              value={category}
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Position
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="position"
              value={position}
            ></Input>
             </Row>
             <Row className='items-center grid grid-cols-1 md:grid-cols-3'>
              <Text
                className="not-italic text-gray_900 w-[150px] mb-2"
                variant="h6"
              >
                Institution
              </Text>         
               <Input
              className="w-[100%]"
              wrapClassName="bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[160px] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
              name="institution"
              value={data?.name}
            ></Input>
             </Row>
                              </Column>
              </div>
          )}
        </Formik> 
        </Box> )
}

export default ViewAccount