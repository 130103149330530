import { Box, LinearProgress, Modal } from "@mui/material";
import { useGetGlobalItemsQuery, useGetReferenceItemsQuery } from "features/item/referenceItemApiSlice";
import MaterialTable from "material-table";
import React, { forwardRef, useState } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useDeleteItemMutation, useDeleteKitMutation, useGetKitsQuery, useUpdateKitMutation } from "features/item/itemApiSlice";
import { useGetReferenceKitsQuery } from "features/item/referenceKitApiSlice";
import AddItemsModal from "components/Modals/AddItem";
import KitItems from "components/KitItems";
import { BounceLoader } from "react-spinners";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const AddKitsList = () => {
    const [x, setX] = React.useState();
    const handleOpen = () => setOpen(true);
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);


  const { data: result, isLoading } = useGetReferenceKitsQuery();
  var lookupObject = result?.reduce(function (acc, cur, i) {
    acc[cur.id] = cur.name;

    return acc;
  }, {});
  const [errMsg, setErrMsg] = useState("");
  const editable = structuredClone(result);
  const selectedRow = React.useRef([]);
  const [successMessage, setSuccessMsg] = useState("");
  const [updateKit, {isLoadingDelete}] = useUpdateKitMutation();
  const [deleteKit, { isLoadingUpdate }] = useDeleteKitMutation();
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };
  function chooseModal(element) {
    setX(<AddItemsModal isKit={true} dataa={element.id} name={element.name} brand={element.brand} description={element.description} />);
  }
  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter by ID",
    },
    {
      title: "Name",
      field: "name",
      align: "left",
      validate: (rowData) => {
        if (rowData.name === undefined || rowData.name == "") {
          return "Required";
        } else return true;
      },
      filterPlaceholder: "Filter by Name",
    },
    {
      title: "Description",
      field: "description",
      align: "left",
      onClick:(rowData) => {
      },
      filterPlaceholder: "Filter by Description",
      validate: (rowData) => {
        if (rowData.description === undefined || rowData.description == "") {
          return "Required";
        } else return true;
      },
    },
  ];
  return (
    <>
     <Modal
        open={open}
        onClose={handleClose}
        sx={{ overflow: "scroll" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <AddItemsModal />}
      </Modal>
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
          
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        
    <MaterialTable
            options={{
              showTitle: false,
              sorting: true,
              emptyRowsWhenPaging: false,
              search: true,
              filtering: true,
              pageSizeOptions: [5, 10, 15],
              exportButton: true,
              exportAllData: true,
              //   selection: true,
              showSelectAllCheckbox: true,
              showTextRowsSelected: true,

              selectionProps: (rowData) => ({ color: "primary" }),
              headerSelectionProps: (header) => ({ color: "primary" }),
              searchFieldAlignment: "left",
              grouping: true,
              columnsButton: true,
              rowStyle: {
                background: "rgba(40, 54, 96, 0.2)",
                color: "#181c32",
              },

              headerStyle: {
                background: "rgba(40, 54, 96, 0.2)",
                color: "#181c32",
                fontWeight: "bolder",
              },
              filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
              detailPanelColumnStyle: { background: "rgba(40, 54, 96, 0.2)" },
              actionsColumnIndex: 8,
            }}
            onSelectionChange={(e) => {
              handleClick(e);
            }}
            onRowClick={(event, rowData) => {
              chooseModal(rowData);
              handleOpen();
            }}
            columns={columns}
            data={editable}
            icons={tableIcons}
            detailPanel={rowData => 
            
                rowData.items?.map((item,idx)=>{
                return  <KitItems id={item}  />
              }
                )

                
          
        }

          /> )}   </>
  );
};

export default AddKitsList;
