import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import {
  faCheck,
  faCheckCircle,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Column } from "components/Column";
import { Text } from "components/Text";
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Card from "components/Card";
import { Row } from "components/Row";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useCreateOrderMutation,
  useDeleteCartItemMutation,
  useGetCartItemsQuery,
} from "features/order/ordersApiSlice";

import { useGetGlobalItemsClientQuery } from "features/item/clientItemsSlice";
import Toast from "components/Toast";
import { Button } from "components/Button";
import { BounceLoader } from "react-spinners";
import { Datepickerr } from "components/Datepicker";
import SelectLocation from "components/Mappp";
import Paginationn from "components/Pagination";
import { Formik, useFormikContext } from "formik";
import { useNavigate } from "react-router-dom";
import { Input } from "components/Input";
import moment from "moment";
import CardForItems from "components/Card/CardForItems";
import KitsLookUp from "components/LookUpElements/refKit";
import ItemsLookUp from "components/LookUpElements/refItem";
import ProductsLookUp from "components/LookUpElements/productLookup";
function Cart({}) {
  const [deleteItem, { isLoading }] = useDeleteCartItemMutation();
  const [createOrder, { isLoadingOrder }] = useCreateOrderMutation();
  const formik = useFormikContext();

  const {
    data,
    isLoading: isLoadingg,
    isSuccess,
    isError,
    error,
  } = useGetCartItemsQuery();
  const onLoad = useCallback((map) => (mapRef.current = map), []);
  const mapRef = useRef();
  const [useMap, setUseMapp] = useState(true);

  const [errMsg, setErrMsg] = useState("");
  const [deliveryDate, setdeliveryDate] = useState();
  const [successMessage, setSuccessMsg] = useState("");
  const { data: result } = useGetGlobalItemsClientQuery();
  const editable = structuredClone(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(3);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = editable?.slice(indexOfFirstData, indexOfLastData);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const [openOrder, setOpenOrder] = React.useState(false);
  const [OpenDia, setOpenDia] = React.useState(false);
  const navigate = useNavigate();

  const handleClickClose = () => {
    setOpenDia(false);
  };
  var lookupObjectItem = result?.reduce(function (acc, cur, i) {
    acc[cur.id] = cur?.name;

    return acc;
  }, {});

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #ffffff",
    borderRadius: 5,
    boxShadow: 24,
    py: 5,
    // pl: 5,
  };
  async function removeItem(id) {
    try {
      const itemData = await deleteItem(id).unwrap();
      setErrMsg("");
    } catch (err) {
      if (!err) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Invalid Credentials");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Failed to remove item");
      }
    }
  }
  const list = currentData?.map((element) => {
    return (
      <CardForItems
        modal={true}
        qty={element?.quantity}
        id={element?.id}
        icon={
          <FontAwesomeIcon
            type="outline"
            className="px-[auto]"
            color="#ffffff"
            fontSize={30}
            width={40}
            icon={faBoxesStacked}
          />
        }
        //TODO: Fix the backend with the is kit option and implement
        row11={<ProductsLookUp id={element?.product} />}
        // row11={isKit?<KitsLookUp id={element?.product} />:<ItemsLookUp id={element?.product} />}
        // row11={lookupObjectItem[element?.product]}
        // row12={element.client_institution}
        button2name=""
        button2Icon={faClose}
        button2action={() => removeItem(element?.id)}
        button2color="#be3b3b"
        row12={`${element?.quantity}Qty`}
      />
    );
  });

  return (
    <>
      <Box
        visibility={!openOrder ? "hidden" : "visible"}
        className="w-[60%] md:w-[600px] h-[75vh]"
        sx={style}
      >
        <div style={{ overflow: "scroll", height: "60vh" }}>
          <Column className="gap-3 mx-[auto] w-[80%] xl-[95%]">
            {errMsg && <Toast message={errMsg} />}
            {successMessage && (
              <Toast type="success" message={successMessage} />
            )}
            <div className=" content-end grid grid-cols-2">
              <Text variant="h1" className="not-italic text-gray_900">
                Order
              </Text>
            </div>
            {isLoadingOrder ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  height: "50px",
                }}
              >
                <BounceLoader
                  color="#283660"
                  // className="bg-green_400"
                />
              </Box>
            ) : (
              <>
                <Dialog
                  open={OpenDia}
                  onClose={handleClickClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div
                    style={{
                      border: "2px solid #ffffff",
                      borderRadius: 3,
                      boxShadow: 24,
                    }}
                    className="p-3"
                  >
                    <DialogTitle id="alert-dialog-title">
                      <Text variant="h4">
                        Your order has been successfuly created.{" "}
                      </Text>
                    </DialogTitle>
                    <DialogContent className="items-center justify-center justify-self-center">
                      <Column className="items-center mx-[auto]">
                        <FontAwesomeIcon
                          type="outline"
                          className="px-[auto] text-center self-center"
                          color="#283660"
                          fontSize={30}
                          width={40}
                          icon={faCheckCircle}
                        />
                      </Column>
                    </DialogContent>
                    <DialogActions>
                      <Column className="items-center mx-[auto]">
                        <Button
                          className="w-[auto]"
                          onClick={() => {
                            navigate("/organization/offers");
                            handleClickClose();
                          }}
                          autoFocus
                        >
                          Go to Offers page
                        </Button>
                      </Column>
                    </DialogActions>
                  </div>
                </Dialog>
                <Formik
                  initialValues={{
                    time: "",
                    address: "",
                    location_lat: 9.0013235,
                    location_lon: 38.7670247,
                    location_name:
                      "Rizq Building | Gazebo Square | ሪዝቅ ህንጻ | ጋዜቦ አደባባይ, Addis Ababa, Ethiopia",
                  }}
                  // validationSchema={validationSchema}
                  onSubmit={async (values, { resetForm }) => {
                    // let formattedDate = `${
                    //   deliveryDate.getMonth() + 1
                    // }/${deliveryDate.getDate()}/${deliveryDate.getFullYear()}`;

                    try {
                      const itemData = await createOrder({
                        deliver_to: useMap
                          ? values.location_name
                          : values.address,
                        deliver_by:
                          deliveryDate == undefined
                            ? ""
                            : moment(deliveryDate).format("YYYY-MM-DD"),
                        deliver_to_location: {
                          longitude: values.location_lon,
                          latitude: values.location_lat,
                        },
                      }).unwrap();
                      setErrMsg("");
                      // setSuccessMsg("Successfully created order");
                      setOpenDia(true);
                    } catch (err) {
                      if (!err) {
                        setErrMsg("No Server Response");
                      } else if (err.data?.deliver_by)
                        setErrMsg("Delivery Address can not be empty");
                      else if (err.data?.deliver_to)
                        setErrMsg("Delivery Time can not be empty");
                      else if (err.status === 400) {
                        setErrMsg("Invalid Credentials");
                      } else if (err.status === 401) {
                        setErrMsg("Unauthorized");
                      } else {
                        setErrMsg("Failed to create order");
                      }
                    }
                  }}
                >
                  {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                      <Column className="gap-1">
                        <Row className="items-center grid grid-cols-1 md:grid-cols-3">
                          <Text
                            className="not-italic text-gray_900 "
                            variant="h6"
                          >
                            Deliver by
                          </Text>
                        </Row>
                        <Datepickerr
                          className="mb-2 pt-1 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[33px] md:h-[30px] sm:h-[30px] h-[30px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px] rounded-radius845 px-3 border bg-gray_50 border-gray_50 border-solid"
                          onChange={(value) => {
                            setdeliveryDate(value);
                          }}
                          // {...formik.getFieldProps("time")}
                        />
                        {/* <Row className="items-center grid grid-cols-1 md:grid-cols-3"> */}
                        <Text
                          className="not-italic text-gray_900 "
                          variant="h6"
                        >
                          Deliver to
                        </Text>
                        <Input
                          className="w-[100%]"
                          wrapClassName={
                            useMap
                              ? "bg-gray_400 2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                              : "2xl:h-[47px] 2xl:mt-[9px] 3xl:h-[56px] 3xl:mt-[10px] flex lg:h-[40px] md:h-[36px] sm:h-[36px] h-[36px] lg:mt-[6px] w-[100%] sm:w-[300px] xl:h-[41px] xl:mt-[8px]"
                          }
                          name="address"
                          disabled={useMap}
                          {...formik.getFieldProps("address")}

                          // name="deliverTo"
                          // value={deliverTo}
                          // onChange={(val)=>setdeliverTo(deliverTo)}
                        ></Input>

                        {/* </Row> */}
                        <Row className="items-center my-[1%] grid grid-cols-1">
                          <FormGroup className="ml-[1.3%]">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) => setUseMapp(e.target.checked)}
                                  defaultChecked
                                />
                              }
                              label="Use the location name on the Map as my store address"
                            />
                          </FormGroup>
                        </Row>
                        <SelectLocation
                          placeholder={"Enter Delivery Location on Map"}
                          disabled={true}
                        />

                        <Button
                          type="submit"
                          className="mt-[10px] w-[200px]"
                          children={<Text>Create Order</Text>}
                        ></Button>
                      </Column>
                    </form>
                  )}
                </Formik>
              </>
            )}
          </Column>
        </div>
      </Box>

      <Box
        visibility={openOrder ? "hidden" : "visible"}
        className="w-[60%] md:w-[600px] h-[75vh]"
        sx={style}
      >
        <div style={{ overflow: "scroll", height: "65vh" }}>
          <Column className="gap-3 mx-[auto] w-[80%] xl-[95%]">
            {errMsg && <Toast message={errMsg} />}
            {successMessage && (
              <Toast type="success" message={successMessage} />
            )}
            <div className="mb-[3%] content-end grid grid-cols-2">
              <Text variant="h1" className=" not-italic text-gray_900">
                Items
              </Text>
            </div>
            {isLoadingg ? (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  height: "50px",
                }}
              >
                <BounceLoader
                  color="#283660"
                  // className="bg-green_400"
                />
              </Box>
            ) : data?.length != 0 ? (
              <>
                {list}
                <Paginationn
                  dataPerPage={dataPerPage}
                  totaldata={editable?.length}
                  paginate={paginate}
                />

                {/* <Row className="items-center grid grid-cols-1 md:grid-cols-3">
              <Text className="not-italic text-gray_900 " variant="h6">
                Deliver by
              </Text>
              <Datepickerr onChange={(value) => setdeliveryDate(value)} />
            </Row> */}
                {/* <SelectLocation disabled={true} /> */}
                <Button
                  type="submit"
                  onClick={() => {
                    setOpenOrder(true);
                  }}
                  className="w-[200px]"
                  children={<Text>Proceed to Checkout</Text>}
                ></Button>
              </>
            ) : (
              <Text variant="h6" className=" not-italic text-gray_900">
                No Items in Cart
              </Text>
            )}
            {/* {data?.length!=0?? <h1>rsgd</h1>} */}

            {/* } */}
          </Column>
        </div>
      </Box>
    </>
  );
}

Cart.propTypes = {
  icon: PropTypes.object,
  data: PropTypes.array,
  title: PropTypes.string,
  desc: PropTypes.string,
};
Cart.defaultProps = {
  data: [
    { company: "Thermo", desc: 500 },
    { company: "NEB", desc: 500 },
    { company: "Thermo", desc: 500 },
    { company: "NEB", desc: 500 },
  ],
  title: "International Clinical Laboratories",
  desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  icon: faBoxesStacked,
};

export default Cart;
