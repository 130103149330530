import { apiSlice } from "../../app/api/apiSlice";

export const ReferenceItemApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createReferenceItem: builder.mutation({
      query: (credentials) => ({
        // mode: 'cors',
        url: "/inventory/global/items/",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["GlobalItem"],
    }),

    getReferenceItems: builder.query({
      query: () => "inventory/global/items/",
      keepUnusedDataFor: 5,
      providesTags: ["GlobalItem"],
    }),

    getReferenceItem: builder.query({
      query: (id) => "/inventory/global/items/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["GlobalItem"],
    }),
    getProduct: builder.query({
      query: (id) => "inventory/global/products/" + id + "/",
      keepUnusedDataFor: 5,
      providesTags: ["GlobalItem"],
    }),
  }),
});

export const {
  useCreateReferenceItemMutation,
  useGetReferenceItemQuery,
  useGetReferenceItemsQuery,
  useGetProductQuery,
} = ReferenceItemApiSlice;
