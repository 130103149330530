import { Box } from '@mui/material';
import { Column } from 'components/Column';
import { Text } from 'components/Text';
import React from 'react'
import PropTypes from "prop-types";
import Card from 'components/Card';
import { useNavigate } from "react-router-dom";

import { Row } from 'components/Row';
import { faBoxesStacked, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Button';
function GenerateAlert({title,desc,data,lon,lat}) {
    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #ffffff',
        borderRadius:5,
        boxShadow: 24,
        p:3,
       
      };
      const x=   <Card modal={true} icon={<FontAwesomeIcon
        type="outline"
       className="px-[auto]"
       color="#ffffff"
       fontSize={30}
       width={40}
       icon={ faBoxesStacked}
     
   />} row11='Ibuprofen' row12='Gofen' row41='10,000Qty' button1name='Download' row31=''/>
      
   
  return (
    <Box height={200} sx={style}>
        <Column className='gap-3 items-center mx-[auto] xl:w-[95%]'>
    {/* <div className='mb-[3%] grid grid-rows-2 place-content-center'> */}
      <Text  variant='h1'className='not-italic text-gray_900'>Generated Successfuly</Text>
      <FontAwesomeIcon
            type="outline"
           className="px-[auto]"
           color="#283660"
           fontSize={30}
           width={40}
           icon={ faCheckCircle}
       />
      <Button onClick={()=>navigate('/orderHistory')} type='submit' className="w-[200px]" children={<Text>Go to Order History</Text>}  ></Button>

    {/* </div > */}
   
       </Column>         
    
  </Box>  )
}

GenerateAlert.propTypes = {
   icon: PropTypes.object,
    data: PropTypes.array,
    title: PropTypes.string,
    desc: PropTypes.string,
   
  }
GenerateAlert.defaultProps = {
    data: [{company:'Thermo', desc:500},{company:'NEB', desc: 500},{company:'Thermo', desc:500},{company:'NEB', desc: 500}],
   title: 'International Clinical Laboratories',
   desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
   icon: faBoxesStacked
  };

export default GenerateAlert