import React from "react";
import Setnewpassword from "pages/Auth/Setnewpassword";
import NewCompany from "pages/NewCompany";
import GlobalInv from "pages/GlobalInv";
import ForgotPasswordCheckEmail from "pages/Auth/ForgotPasswordCheckEmail";
import NewUser from "pages/NewUser";
import EditUser from "pages/EditUser";
import ForgotPassword from "pages/Auth/ForgotPassword";
import EditAccount from "pages/Dashboard/EditAccount";
import ViewUsers from "pages/Dashboard/ViewUsers";
import Login from "pages/Auth/Login";
import Home1 from "pages/Dashboard/DashboardHome";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Layout from "components/Layout";
import RequireAuth from "features/auth/RequireAuth";
import OrgPage from "pages/Organization/orgHome";
import { Dashboard } from "@mui/icons-material";
import DashboardHome from "pages/Dashboard/DashboardHome";
import OfficerHome from "pages/Officer/OfficerHome";
import SupplierHome from "pages/Supplier/SupplierHome";
import GlobalInventoryPage from "pages/Officer/GlobalInventory";
import OrderHistoryPage from "pages/Officer/OrderHistory";
import ViewOrdersPage from "pages/Officer/ViewOrders";
import ReviewItemsPage from "pages/Officer/ReviewItems";
import MyItemsPage from "pages/Supplier/MyItems";
import OrderHistoryPageSupplier from "pages/Organization/OrderHistory";
import OffersPage from "pages/Organization/Offers";
import OrderItemsPage from "pages/Organization/Items";
import OrderHistoryPageOrg from "pages/Organization/OrderHistory";
import EditAccountPageOfficer from "pages/Officer/EditAccount";
import EditAccountPageSupplier from "pages/Supplier/EditAccount";
import EditAccountPageOrg from "pages/Organization/EditAccount";
import ErrorPage from "pages/ErrorPage";
import ViewInstitutionsPage from "pages/Dashboard/ViewInstitutions";
import ReferenceListPage from "pages/Officer/ReferenceList";
import AddItemPage from "pages/Supplier/AddItem";
import ResetPasswordPage from "pages/Auth/ResetPassword";
import SetnewpasswordPage from "pages/Auth/Setnewpassword";
import { useGetInfoQuery } from "features/auth/authApiSlice";
import { Box } from "@mui/system";
import { BounceLoader } from "react-spinners";

const ProjectRoutes = () => {

  return (
    <>
      {/* { isLoading ? (
      <Box
        sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
      >
        <BounceLoader
          color="#283660"
          // className="bg-green_400"
        />
      </Box>
    ) : ( */}
      <Router>
        <Routes>
          //{" "}
          <Route path="/" element={<Layout />}>
            {/* Public Routes */}
            <Route index path="/" element={<Login />} />
            <Route
              index
              path="/users/password-set"
              element={<ResetPasswordPage />}
            />
            <Route
              index
              path="/users/password_reset"
              element={<SetnewpasswordPage />}
            />
            <Route
              path="/forgotpasswordcheckemail"
              element={<ForgotPasswordCheckEmail />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/error" element={<ErrorPage />} />
            {/* Protected Dashboard Routes */}
            <Route element={<RequireAuth allowedRole="admin" />}>
              <Route path="/dashboard" element={<DashboardHome />} />
              <Route path="/dashboard/users" element={<ViewUsers />} />
              <Route
                path="/dashboard/institutions"
                element={<ViewInstitutionsPage />}
              />
              <Route path="/dashboard/account" element={<EditAccount />} />
            </Route>
            {/*  Protected Officer pages */}
            <Route element={<RequireAuth allowedRole="soyo" />}>
              <Route path="/officer" element={<OfficerHome />} />
              <Route
                path="/officer/globalinventory"
                element={<GlobalInventoryPage />}
              />
              <Route
                path="/officer/referenceList"
                element={<ReferenceListPage />}
              />
              <Route
                path="/officer/orderhistory"
                element={<OrderHistoryPage />}
              />
              <Route path="/officer/orders" element={<ViewOrdersPage />} />
              <Route
                path="/officer/reviewitems"
                element={<ReviewItemsPage />}
              />
              <Route
                path="/officer/account"
                element={<EditAccountPageOfficer />}
              />
            </Route>
            {/* Protected Supplier pages */}
            <Route element={<RequireAuth allowedRole="supplier" />}>
              <Route path="/supplier" element={<SupplierHome />} />
              <Route path="/supplier/myitems" element={<MyItemsPage />} />
              <Route path="/supplier/additem" element={<AddItemPage />} />
              <Route
                path="/supplier/account"
                element={<EditAccountPageSupplier />}
              />
            </Route>

            {/* Protected Organization Pages */}
            <Route element={<RequireAuth allowedRole="client" />}>
              <Route path="/organization" element={<OrgPage />} />
              <Route
                path="/organization/order"
                element={<OrderHistoryPageOrg />}
              />
              <Route path="/organization/offers" element={<OffersPage />} />
              <Route
                path="/organization/orderitem"
                element={<OrderItemsPage />}
              />
              <Route
                path="/organization/account"
                element={<EditAccountPageOrg />}
              />
            </Route>

            <Route path="/dhiwise-dashboard" element={<Home />} />
          </Route>
        </Routes>
      </Router>
      {/* )} */}
    </>
  );
};

export default ProjectRoutes;
