import React, { forwardRef, useEffect, useState } from "react";
import { Badge, Box, Modal } from "@mui/material";
import { Column, Row, Text } from "components";
import { useNavigate } from "react-router-dom";
import Header from "components/Header/index.jsx";
import { faCartShopping, faUser } from "@fortawesome/free-solid-svg-icons";
import NewOrder from "components/Modals/NewOrder";
import Cart from "components/Modals/Cart";
import { useDispatch } from "react-redux";
import { logOut } from "features/auth/authSlice";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import { useGetGlobalItemsClientQuery } from "features/item/clientItemsSlice";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ShoppingCartOutlined from "@material-ui/icons/ShoppingCartOutlined";
import ViewColumn from "@material-ui/icons/ViewColumn";
import KitItems from "components/KitItems";
import { BounceLoader } from "react-spinners";
import { useGetReferenceKitQuery } from "features/item/referenceKitApiSlice";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { InfoOutlined } from "@material-ui/icons";
import KitInfo from "components/Modals/KitInfo";
import Footer from "components/Footer";
import { useGetCartItemsQuery } from "features/order/ordersApiSlice";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox  {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt  {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const OrderItemsPage = () => {
  const navigate = useNavigate();
  const [x, setX] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const selectedRow = React.useRef([]);
  const [kitId, setKitId] = useState("");
  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetGlobalItemsClientQuery();

  const { data: cartItems, isLoading: isLoadingg } = useGetCartItemsQuery();
  const editableCart = structuredClone(cartItems);
  useEffect(() => {
    setCount(editableCart?.length);
  }, [cartItems]);

  const {
    data: kitItem,
    isLoading: isLoading2,
    refetch: refetch2,
  } = useGetReferenceKitQuery(kitId != "" ? kitId : skipToken);

  const editable = structuredClone(records);

  const handleClick = (rows) => {
    selectedRow.current = rows;
  };
  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Item Name",
      field: "name",
      align: "left",

      filterPlaceholder: "Filter",
    },
    {
      title: "Item Type",
      field: "type",
      align: "left",
      lookup: { item: "Item", kit: "Kit" },

      filterPlaceholder: "Filter",
    },

    {
      title: "Brand",
      field: "brand",
      align: "left",
      emptyValue: () => <em>Not Applicable</em>,
      // type: "strin",

      validate: (rowData) => {
        if (rowData.brand === undefined || rowData.brand == "")
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Description",
      field: "description",
      align: "left",
      // type: "numeric",

      validate: (rowData) => {
        if (rowData.description === undefined || rowData.description == "")
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
  ];

  function chooseModal(idx, rowData) {
    switch (idx) {
      case 1:
        setX(<NewOrder data={rowData} />);
        break;
      case 2:
        setX(<Cart />);
        break;
      case 3:
        setX(<KitInfo id={rowData} />);
        break;
    }
  }
  var menu = [
    {
      title: "Items",
      dropdown: false,
      route_path: () => navigate("/organization/orderitem"),
      icon: "",
    },
    {
      title: "Orders",
      dropdown: true,
      entries: [
        { title: "Offers", route_path: () => navigate("/organization/offers") },
        {
          title: "Order History",
          route_path: () => navigate("/organization/order"),
        },
      ],
      icon: "",
    },
    {
      dropdown: true,
      title: "",
      entries: [
        {
          title: "Account Setting",
          route_path: () => {
            navigate("/organization/account");
          },
        },
        {
          title: "Log Out",
          route_path: () => {
            dispatch(logOut());
            navigate("/");
          },
        },
      ],
      icon: faUser,
    },
    {
      title: "",
      route_path: () => {
        chooseModal(2);
        handleOpen();
      },
      icon: faCartShopping,
    },
  ];

  return (
    <>
      <Modal
        open={open}
        sx={{ overflow: "hidden" }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {x ?? <NewOrder />}
      </Modal>
      <Header
        homePath="/organization"
        menus={menu}
        cart={
          <div
            onClick={() => {
              chooseModal(2);
              handleOpen();
            }}
          >
            <Badge
              className="px-[auto] mx-2"
              color="primary"
              badgeContent={count}
            >
              <ShoppingCartIcon htmlColor="#ffffff" />
            </Badge>
          </div>
        }
      />
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <Column className="mx-[auto]  w-[90%]  md:w-[80%]">
          <Row className="mb-[3%] content-end grid grid-cols-2">
            <Column>
              <Text variant="h1" className="not-italic text-gray_900">
                Items
              </Text>
              <Text
                className="mt-[2px] sm:mt-[2px] md:mt-[3px] lg:mt-[5px] xl:mt-[7px] 2xl:mt-[9px] 3xl:mt-[12px] not-italic text-bluegray_300 w-[auto]"
                variant="body2"
              >
                Select the Cart Icon next to the Product of your choice to add
                item to Cart
              </Text>
            </Column>
          </Row>
          <Column className="grid mb-2 pb-[5rem]">
            <MaterialTable
              editable={
                {
                  // onRowUpdate: (newRow, oldRow) =>
                  //   new Promise(async (resolve, reject) => {
                  //     try {
                  //       const userData = await updateUser({
                  //         "id":oldRow.id,
                  //         "username": oldRow.username,
                  //         "first_name": newRow.firstName,
                  //         "last_name": newRow.lastName,
                  //         "email": newRow.email,
                  //         "phone": newRow.phone,
                  //         "role": newRow.role,
                  //         "institution": newRow.institution,
                  //         "position": newRow.position
                  //       }).unwrap();
                  //       console.log(userData);
                  //       setErrMsg("");
                  //       setSuccessMsg("Successfully edited user");
                  //     } catch (err) {
                  //       console.log(err.data);
                  //       if (!err) {
                  //         setErrMsg("No Server Response");
                  //       } else if (err.status === 400) {
                  //         setErrMsg("Invalid Credentials");
                  //       } else if (err.status === 401) {
                  //         setErrMsg("Unauthorized");
                  //       } else {
                  //         setErrMsg("Failed to create a global item");
                  //       }
                  //     }
                  //     setTimeout(() => resolve(), 500);
                  //   }),
                  // onRowDelete: (Row) =>
                  //   new Promise(async (resolve, reject) => {
                  //     try {
                  //       const userData = await deleteUser(Row.id).unwrap();
                  //       console.log(userData);
                  //       setErrMsg("");
                  //       setSuccessMsg("Successfully deleted user");
                  //     } catch (err) {
                  //       console.log(err.data);
                  //       if (!err) {
                  //         setErrMsg("No Server Response");
                  //       } else if (err.status === 400) {
                  //         setErrMsg("Invalid Credentials");
                  //       } else if (err.status === 401) {
                  //         setErrMsg("Unauthorized");
                  //       } else {
                  //         setErrMsg("Failed to create a global item");
                  //       }
                  //     }
                  //     setTimeout(() => resolve(), 500);
                  //   }),
                }
              }
              options={{
                showTitle: false,
                sorting: true,
                emptyRowsWhenPaging: false,
                search: true,
                filtering: true,
                pageSizeOptions: [5, 10, 15],
                exportButton: true,
                columnResizable: true,
                exportAllData: true,
                // selection: true,
                showSelectAllCheckbox: true,
                showTextRowsSelected: true,

                selectionProps: (rowData) => ({ color: "primary" }),
                headerSelectionProps: (header) => ({ color: "primary" }),
                searchFieldAlignment: "left",
                grouping: true,
                columnsButton: true,
                rowStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                  color: "#181c32",
                },

                headerStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                  color: "#181c32",
                  fontWeight: "bolder",
                },
                filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
                detailPanelColumnStyle: {
                  background: "rgba(40, 54, 96, 0.2)",
                },
                actionsColumnIndex: 10,
              }}
              actions={[
                {
                  icon: ShoppingCartOutlined,

                  tooltip: "Add to Cart",
                  onClick: (event, rowData) => {
                    chooseModal(1, rowData);
                    handleOpen();
                  },
                },
                (rowData) => {
                  return {
                    icon: InfoOutlined,
                    tooltip: "Get more information",
                    disabled: rowData.type == "item",
                    // hidden: rowData.status === "active",
                    onClick: (event, rowData) => {
                      chooseModal(3, rowData.id);
                      handleOpen();
                    },
                  };
                },
              ]}
            
              // onSelectionChange={(e) => {
              //   handleClick(e);
              //   console.log(selectedRow);
              // }}
              columns={columns}
              data={editable}
              icons={tableIcons}
            />
          </Column>
        </Column>
      )}
      <Footer />
    </>
  );
};

export default OrderItemsPage;
