import { Box, LinearProgress } from "@mui/material";
import {
  useGetGlobalItemsQuery,
  useGetReferenceItemsQuery,
} from "features/item/referenceItemApiSlice";
import MaterialTable from "material-table";
import React, { forwardRef, useState } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  useDeleteItemMutation,
  useDeleteKitMutation,
  useGetKitsQuery,
  useUpdateKitMutation,
} from "features/item/itemApiSlice";
import { useGetReferenceKitsQuery } from "features/item/referenceKitApiSlice";
import { BounceLoader } from "react-spinners";
import KitsLookUp from "components/LookUpElements/refKit";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MyKitsList = () => {
  const {
    data: records,
    isLoading,
    isSuccess,
    isError,
    refetch,
    isFetching,
    error,
  } = useGetKitsQuery();

  const [errMsg, setErrMsg] = useState("");
  const editable = structuredClone(records);
  const selectedRow = React.useRef([]);
  const [successMessage, setSuccessMsg] = useState("");
  const [updateKit, { isLoadingDelete }] = useUpdateKitMutation();
  const [deleteKit, { isLoadingUpdate }] = useDeleteKitMutation();
  const handleClick = (rows) => {
    selectedRow.current = rows;
  };
  const columns = [
    {
      title: "ID",
      field: "id",
      align: "left",
      type: "numeric",
      editable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Kit Name",
      field: "refkit",
      align: "left",
      editable: false,
      render: (rowData) => <KitsLookUp id={rowData.refkit} />,
      // lookup: lookupObject,
      filterPlaceholder: "Filter",
    },
    {
      title: "Supplier Price",
      field: "supplier_price",
      align: "left",
      type: "currency",
      validate: (rowData) => {
        if (rowData.supplier_price <= 0)
          return "Price should be greater than 0";
        else if (
          rowData.supplier_price === undefined ||
          rowData.supplier_price == ""
        ) {
          return "Required";
        } else return true;
      },
      currencySetting: { currencyCode: "ETB", minimumFractionDigits: 0 },
      searchable: false,
      filterPlaceholder: "Filter",
    },
    {
      title: "Quantity",
      field: "quantity",
      align: "left",
      type: "numeric",

      validate: (rowData) => {
        if (rowData.quantity <= 0) return "Quantity should be greater than 0";
        else if (rowData.quantity === undefined || rowData.quantity == "")
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
    {
      title: "Catalog Number",
      field: "catalog_number",
      align: "left",

      validate: (rowData) => {
        if (
          rowData.catalog_number === undefined ||
          rowData.catalog_number == ""
        )
          return "Required";
        else return true;
      },
      filterPlaceholder: "Filter",
    },
  ];
  return (
    <>
      {isLoading ? (
        <Box
          sx={{ position: "absolute", top: "50%", left: "50%", height: "50px" }}
        >
          <BounceLoader
            color="#283660"
            // className="bg-green_400"
          />
        </Box>
      ) : (
        <MaterialTable
          editable={{
            onRowUpdate: (newRow, oldRow) =>
              new Promise(async (resolve, reject) => {
                try {
                  const itemData = await updateKit({
                    id: oldRow.id,
                    // refkit: oldRow.refkit,
                    quantity: newRow.quantity,
                    supplier_price: newRow.supplier_price,
                    catalog_number: newRow.catalog_number,
                  }).unwrap();
                  setErrMsg("");
                  setSuccessMsg("Successfully edited kit");
                } catch (err) {
                  if (!err) {
                    setErrMsg("No Server Response");
                  } else if (err.status === 400) {
                    setErrMsg("Invalid Credentials");
                  } else if (err.status === 401) {
                    setErrMsg("Unauthorized");
                  } else {
                    setErrMsg("Failed to create a global item");
                  }
                }
                setTimeout(() => resolve(), 500);
              }),
            onRowDelete: (Row) =>
              new Promise(async (resolve, reject) => {
                try {
                  const itemData = await deleteKit(Row.id).unwrap();
                  setErrMsg("");
                  setSuccessMsg("Successfully deleted kit");
                } catch (err) {
                  if (!err) {
                    setErrMsg("No Server Response");
                  } else if (err.status === 400) {
                    setErrMsg("Invalid Credentials");
                  } else if (err.status === 401) {
                    setErrMsg("Unauthorized");
                  } else {
                    setErrMsg("Failed to create a global item");
                  }
                }
                setTimeout(() => resolve(), 500);
              }),
          }}
          options={{
            showTitle: false,
            sorting: true,
            emptyRowsWhenPaging: false,
            search: true,
            filtering: true,
            pageSizeOptions: [5, 10, 15],
            exportButton: true,
            exportAllData: true,
            selection: true,
            showSelectAllCheckbox: true,
            showTextRowsSelected: true,

            selectionProps: (rowData) => ({ color: "primary" }),
            headerSelectionProps: (header) => ({ color: "primary" }),
            searchFieldAlignment: "left",
            grouping: true,
            columnsButton: true,
            rowStyle: {
              background: "rgba(40, 54, 96, 0.2)",
              color: "#181c32",
            },

            headerStyle: {
              background: "rgba(40, 54, 96, 0.2)",
              color: "#181c32",
              fontWeight: "bolder",
            },
            filterRowStyle: { background: "rgba(40, 54, 96, 0.2)" },
            detailPanelColumnStyle: { background: "rgba(40, 54, 96, 0.2)" },
            actionsColumnIndex: 8,
          }}
          onSelectionChange={(e) => {
            handleClick(e);
          }}
          columns={columns}
          data={editable}
          icons={tableIcons}
        />
      )}
    </>
  );
};

export default MyKitsList;
