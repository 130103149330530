import { Text } from "components/Text";
import { useGetInstitutionQuery } from "features/institution/institutionApiSlice";
import { useGetReferenceKitQuery } from "features/item/referenceKitApiSlice";
import React from "react";

const KitsLookUp = (id, color) => {
  const { data } = useGetReferenceKitQuery(id.id);
  return (
    <Text className="text-gray_900" variant="h6">
      {data?.name}
    </Text>
  );
};

export default KitsLookUp;
